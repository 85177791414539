import React, { useEffect, useState } from 'react';
import Logo from 'assets/images/atropos_logo.png';
import { SelectMenu, Button } from '@primer/components';
import { useHistory, useLocation } from 'react-router-dom';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { converBase64toLink } from '../utils/converBase64toLink';
import { BsFillPersonFill } from 'react-icons/bs';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonCustom } from './form-input/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import LogOutUtil from '../utils/LogOutUtil';
import NotificationBanner from './notifications/NotificationBanner';
import NotificationBell from './notifications/NotificationBell';
import {
  resetNotification,
  getAllNotifications,
  resetNotificationCountStart,
  getBadgeNotificationsComplete,
  resetNotificationCountComplete,
  toggleNotificationDropdown,
  clearIntervalTask,
} from 'redux/modules/notifications/actions';
import { getRawToken } from 'utils/validations';
import OrderdetailModal from './OrderdetailModal';
import {
  handleOrderDetailModal,
  setCurrentOrderStatus,
  setMotivation,
} from 'redux/modules/orderDetails/actions';
import ViewAsConfirmationModal from 'features/viewas/ViewAsConfirmationModal';
import { resetViewAs } from 'redux/modules/viewAs/actions';
import ViewAsBanner from 'features/viewas/ViewAsBanner';
import { useSessionStorageState } from 'hooks/useSessionStorageState';

const profileImage = (name, profilePicture) => {
  if (profilePicture) {
    let profil_pic = converBase64toLink(profilePicture);
    if (profil_pic) {
      return <img src={profil_pic} alt={name} />;
    } else {
      return (
        <div className="d-flex flex-justify-center flex-items-center nav-img">
          {name.trim() !== '' ? (
            <span>{name.charAt(0).toUpperCase()} </span>
          ) : (
            <span>
              <BsFillPersonFill />
            </span>
          )}
        </div>
      );
    }
  } else {
    return (
      <div className="d-flex flex-justify-center flex-items-center nav-img">
        {name.trim() !== '' ? (
          <span>{name.charAt(0).toUpperCase()} </span>
        ) : (
          <span>
            <BsFillPersonFill />
          </span>
        )}
      </div>
    );
  }
};

const TopNav = () => {
  const [openViewasModal, setOpenViewasModal] = useState(false);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [viewAsSession, setViewAsSession] = useSessionStorageState({}, 'viewAsSession');
  const history = useHistory();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { pathname } = useLocation();
  const { logOutUser } = LogOutUtil();
  const { getIdTokenClaims } = useAuth0();
  const {
    orderScopeData,
    isNotificationModalOpen,
    orderNotificationDetailsError,
    orderNotificationDetails,
    questionData,
    orderNotificationDetailsLoading,
    pdfFile,
  } = useSelector(({ orderDetails }) => orderDetails);
  const { userData } = useSelector(({ userProfile }) => userProfile);
  const { first_name = '', last_name = '', profile_photo = '' } = userData || {};
  const { allNotificationList } = useSelector(({ notifications }) => notifications);
  const onClickOfButton = () => {
    mixpanel.track('Profile button clicked');
    history.push(urlConstantsEnum.PROFILE);
  };

  const logOutCallbackFuc = () => {
    logOutUser();
  };

  const OnClickOfLogin = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}`,
      appState: { returnTo: window.location.pathname },
    });
  };

  const navigateToDashboard = () => {
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };

  useEffect(() => {
    if (userData?.guid && userData?.is_profile_completed) {
      getUsersAllNotifications();
    }
  }, [userData?.guid, userData?.is_profile_completed]);

  const handleNotificationList = async (data, status) => {
    const token = await getIdTokenClaims();
    dispatch(
      resetNotification({
        token: getRawToken(token),
        userGuid: userData.guid,
        page: 1,
        size: 10,
        params: data,
        callBadgeNotifications: status,
      }),
    );
  };

  const getUsersAllNotifications = async () => {
    if (userData) {
      const token = await getIdTokenClaims();
      dispatch(
        getAllNotifications({
          token: getRawToken(token),
          userGuid: userData.guid,
          page: 1,
          size: 15,
        }),
      );
    }
  };

  const handleNotificationBannerClose = (type) => {
    dispatch(clearIntervalTask());
    dispatch(resetNotificationCountStart(true));
    const key = Object.keys(type)[0];
    dispatch(resetNotificationCountComplete({ [key]: 0 }));
    handleNotificationList(type, false);
  };

  function handleBadgeNotiicationFlow(type) {
    const obj = {};
    for (const key in type) {
      if (type.hasOwnProperty(key)) {
        obj[key] = 0;
      }
    }
    dispatch(clearIntervalTask());
    dispatch(resetNotificationCountStart(true));
    dispatch(getBadgeNotificationsComplete([]));
    dispatch(resetNotificationCountComplete(obj));
    dispatch(toggleNotificationDropdown(true));
    handleNotificationList(type, true);
  }

  function OnClickOfOrderClose() {
    dispatch(handleOrderDetailModal(false));
  }

  function modifyOrder(order, edit) {
    dispatch(handleOrderDetailModal(false));
    history.push(
      `${urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE}/${
        edit === true ? 'edit' : 'reorder'
      }/${order.guid}`,
    );
  }

  const handleModalOnPageChange = () => {
    dispatch(handleOrderDetailModal(false));
    if (
      pathname !== urlConstantsEnum.DASHBOARD &&
      pathname !== urlConstantsEnum.LIBRARY_DASHBOARD &&
      pathname !== urlConstantsEnum.SEARCH_RESULT &&
      pathname !== urlConstantsEnum.LIBRARY_SEARCH_RESULT
    ) {
      history.push(
        orderScopeData?.scopeTabIndex === 1
          ? urlConstantsEnum.LIBRARY_DASHBOARD
          : urlConstantsEnum.DASHBOARD,
      );
    }
  };

  function getOrdersByStatus(status) {
    dispatch(setCurrentOrderStatus(status));
    handleModalOnPageChange();
  }

  function getOrdersByMotivation(motivation) {
    dispatch(setMotivation(motivation));
    handleModalOnPageChange();
  }

  function handleViewasMode() {
    dispatch(resetViewAs());
    setOpenViewasModal(true);
  }

  function handleExitViewas() {
    setViewAsSession({});
    window.location.href = '/dashboard';
  }

  return (
    <>
      <ViewAsBanner />
      {allNotificationList &&
        allNotificationList.hasOwnProperty('banner_success_unread_count') &&
        allNotificationList.hasOwnProperty('banner_failure_unread_count') && (
          <NotificationBanner
            allNotificationList={allNotificationList}
            onCloseNotificationBanner={handleNotificationBannerClose}
            onClickNotificationLink={handleBadgeNotiicationFlow}
          />
        )}
      <div className="topnav d-flex flex-justify-between">
        <div className="logoContainer d-flex flex-content-center">
          <ButtonCustom
            cssClass="portal-logo"
            onClick={() => navigateToDashboard()}
            id={'btn-logo-link'}
          >
            <img src={Logo} alt="Atropos logo" />
          </ButtonCustom>
        </div>
        <div className="d-flex align-items-center">
          {isAuthenticated ? (
            <>
              <NotificationBell
                onClickNotificationBell={handleBadgeNotiicationFlow}
                allNotificationList={allNotificationList}
              />
              <SelectMenu>
                <Button as="summary" id={'btn-profile-button'}>
                  <div className="avatar h6 lh-condensed-ultra d-flex">
                    {profileImage(first_name + ' ' + last_name, profile_photo)}asd
                  </div>
                </Button>
                <SelectMenu.Modal>
                  <SelectMenu.List>
                    <SelectMenu.Item
                      onClick={onClickOfButton}
                      id={'btn-profile-page-btn'}
                    >
                      Profile
                    </SelectMenu.Item>

                    {userData?.is_superuser === 'True' &&
                      !viewAsSession?.impersonate_header &&
                      !viewAsSession?.user?.email && (
                        <SelectMenu.Item onClick={handleViewasMode} id="btn-view-as-btn">
                          View As
                        </SelectMenu.Item>
                      )}

                    {viewAsSession?.impersonate_header && viewAsSession?.user?.email && (
                      <SelectMenu.Item
                        onClick={handleExitViewas}
                        id="btn-Logout-viewas-btn"
                      >
                        Exit View As
                      </SelectMenu.Item>
                    )}

                    <SelectMenu.Item
                      onClick={() => logOutCallbackFuc()}
                      id={'btn-log-out-button'}
                    >
                      Log Out
                    </SelectMenu.Item>
                  </SelectMenu.List>
                </SelectMenu.Modal>
              </SelectMenu>
            </>
          ) : (
            <ButtonCustom
              onClick={() => OnClickOfLogin()}
              cssClass="gradient-btn signin-btn"
              buttonAction="Sign in button clicked"
              id={'btn-sign-in-button'}
            >
              Sign In
            </ButtonCustom>
          )}
        </div>
      </div>
      <OrderdetailModal
        isOpen={isNotificationModalOpen}
        handleClose={OnClickOfOrderClose}
        isLoading={orderNotificationDetailsLoading}
        data={orderNotificationDetails}
        error={orderNotificationDetailsError}
        questionData={questionData}
        pdfFile={pdfFile}
        onModify={() => modifyOrder(orderNotificationDetails, false)}
        onEdit={() => modifyOrder(orderNotificationDetails, true)}
        OnClickOfOrderClose={OnClickOfOrderClose}
        filterByStatus={() => getOrdersByStatus(orderNotificationDetails.status)}
        filterByMotivation={getOrdersByMotivation}
      />
      {openViewasModal && (
        <ViewAsConfirmationModal
          isOpen={openViewasModal}
          onClose={() => setOpenViewasModal(false)}
        />
      )}
    </>
  );
};
export default TopNav;
