import React, { useState, useEffect } from 'react';
import { ButtonCustom } from 'components/form-input/Button';
import { CALENDY_LINK } from 'constants/Constants';
import CustomModal from './CustomModal';
import { VscChromeClose } from 'react-icons/vsc';
import AuthLoader from '../components/AuthLoader';
import { highlightText } from '../utils/highlightText';
import { useDispatch, useSelector } from 'react-redux';
import FileDownloadUtil from '../utils/FileDownloadUtil';
import resourceEnum from '../constants/enum/resourceEnum';
import {
  getPrognostogramPdf,
  handleCancelOrderModal,
} from 'redux/modules/orderDetails/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { getRawToken } from '../utils/validations';
import { BsTelephone } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';
import { HiOutlineEye } from 'react-icons/hi';
import { MdOutlineCancel } from 'react-icons/md';
import { TbFileDownload } from 'react-icons/tb';
import { labels } from 'constants/labels';
import { useSessionStorageState } from 'hooks/useSessionStorageState';
import orderFormErrorStr from 'constants/errorStrings';

const PicotOptions = ({
  orderGuid,
  population,
  intervention,
  control,
  outcome,
  timeframe,
  onModify,
  openPDF,
  questionStatus,
  onEdit,
  onCancelOrder,
  tabIndex,
  textToHighlight,
  conclusion,
  summary,
  order,
}) => {
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const [showModal, setShowModal] = useState(false);
  const [isOpenPdf, setSetOpenPdf] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [viewAsSession, setViewAsSession] = useSessionStorageState({}, 'viewAsSession');
  const {
    resource,
    isFetchingDownloadLinkStarted,
    getFileDownloadError,
    pdfFile,
    isPdfLoading,
  } = useSelector(({ orderDetails }) => orderDetails);
  const { getDownloadUrl } = FileDownloadUtil();
  const caseProductType = order?.product_case_type?.toLowerCase();
  const status = order?.status?.toLowerCase();

  const highlightPicotOptions = (context, picotOptions) => {
    if (textToHighlight && textToHighlight.hasOwnProperty(picotOptions)) {
      for (var key in textToHighlight) {
        if (key === picotOptions) {
          var highlightObj = textToHighlight[key];
          if (highlightObj.text) {
            return highlightText(context, highlightObj.text);
          } else {
            return context;
          }
        }
      }
    } else {
      return context;
    }
  };

  const OnClickOfClose = () => {
    // setShowModal(false);
    dispatch(handleCancelOrderModal(null));
  };
  const onClickOfCancelOrder = () => {
    // setShowModal(true);
    dispatch(handleCancelOrderModal(orderGuid));
  };

  const onDownloadArtifactsButton = async () => {
    getDownloadUrl(orderGuid, resourceEnum.ARTIFACTS);
  };

  const getPdfData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getPrognostogramPdf({
        token: getRawToken(token),
        orderGuid: orderGuid,
      }),
    );
  };

  const isActionRestricted = () => {
    return (
      caseProductType === 'custom' ||
      caseProductType === 'batch' ||
      status === 'in progress'
    );
  };

  const shouldHideCaseDetails = () => !isActionRestricted();
  const isBtnDisabled = () => isActionRestricted();

  return (
    <div className="my-3">
      {shouldHideCaseDetails() && (
        <>
          {summary && (
            <div className="picot-desc mb-4">
              <div className="picot-lable mb-2 picot-label-color">Summary</div>
              <div className="picot-content">
                {highlightPicotOptions(summary, 'summary')}
              </div>
            </div>
          )}

          {population && (
            <div className="picot-desc mb-4">
              <div className="picot-lable mb-2">Population</div>
              <div className="picot-content">
                {highlightPicotOptions(population, 'population')}
              </div>
            </div>
          )}
          {intervention && (
            <div className="picot-desc mb-4">
              <div className="picot-lable mb-2">Intervention</div>
              <div className="picot-content">
                {highlightPicotOptions(intervention, 'intervention')}
              </div>
            </div>
          )}
          {control && (
            <div className="picot-desc mb-4">
              <div className="picot-lable mb-2">Control</div>
              <div className="picot-content">
                {highlightPicotOptions(control, 'control')}
              </div>
            </div>
          )}
          {outcome && (
            <div className="picot-desc mb-4">
              <div className="picot-lable mb-2">Outcome</div>
              <div className="picot-content">
                {highlightPicotOptions(outcome, 'outcome')}
              </div>
            </div>
          )}
          {timeframe && (
            <div className="picot-desc mb-4">
              <div className="picot-lable mb-2">Timeframe</div>
              <div className="picot-content">
                {highlightPicotOptions(timeframe, 'timeframe')}
              </div>
            </div>
          )}
        </>
      )}

      {/* {conclusion && (
        <div className="picot-desc mb-4">
          <div className="picot-lable mb-2 picot-label-color">Conclusions</div>
          <div className="picot-content">
            {highlightPicotOptions(conclusion, 'conclusion')}
          </div>
        </div>
      )} */}

      {caseProductType !== 'custom' &&
        caseProductType !== 'batch' &&
        (order?.initial_clinical_questions || order?.clinical_questions) && (
          <div className="picot-desc mb-4">
            <div className="mb-2 txt size-20 lh-32 w-700 text-color-light">
              Initial Question
            </div>
            <div className="picot-content ">
              <div className="txt size-16 text-color-light-2">
                {order?.initial_clinical_questions
                  ? order?.initial_clinical_questions
                  : order?.clinical_questions
                  ? order?.clinical_questions
                  : null}
              </div>
            </div>
          </div>
        )}

      <div className="btn-container dashboard-btn-container">
        <div className="d-lg-flex">
          {tabIndex !== 2 ? (
            <a
              href={CALENDY_LINK}
              target="_blank"
              className={`blue-btn my-2 my-md-4 d-flex flex-justify-center flex-items-center space-no-wrap ${
                viewAsSession && viewAsSession?.user?.email ? 'outline-btn-disabled' : ''
              } `}
              rel="noreferrer"
              id={'btn-schedule-call-button'}
            >
              <div className="icon-button d-flex flex-justify-center ">
                <BsTelephone />
                <span>{labels.scheduleaCall}</span>
              </div>
            </a>
          ) : (
            ''
          )}
          <ButtonCustom
            cssClass="blue-btn my-2 my-md-4 space-no-wrap"
            onClick={onModify}
            buttonAction={'Modify and reorder button clicked'}
            id={'bt-modify-and-reorder-bbutton'}
          >
            <div className="icon-button d-flex flex-justify-center  ">
              <FaRegEdit />
              <span>{labels.modifyAndReorder}</span>
            </div>
          </ButtonCustom>
          {questionStatus === 'Pending' ? (
            <ButtonCustom
              cssClass={`blue-btn my-2 my-md-4 space-no-wrap ${
                order?.product_case_type === 'ChatRWD' ? 'btn-disabled' : ''
              }`}
              onClick={onEdit}
              buttonAction={'Edit order button clicked'}
              id={'btn-edit-order-button'}
              disabled={order?.product_case_type === 'ChatRWD'}
            >
              <div className="icon-button d-flex flex-justify-center ">
                <BiEditAlt />
                <span>{labels.editOrder}</span>
              </div>
            </ButtonCustom>
          ) : (
            <>
              <ButtonCustom
                cssClass={`blue-btn my-2 my-md-4 space-no-wrap ${
                  questionStatus !== 'Completed' || isBtnDisabled() ? 'btn-disabled' : ''
                }`}
                disabled={
                  questionStatus !== 'Completed' || isBtnDisabled() ? true : false
                }
                onClick={getPdfData}
                buttonAction={'View Results button clicked'}
                id={'btn-view-prognostogram-button'}
              >
                <div className="icon-button d-flex flex-justify-center ">
                  <HiOutlineEye />
                  <span>View Results</span>
                </div>
              </ButtonCustom>
              {showLoader === true || (isPdfLoading && questionStatus === 'Completed') ? (
                <div className="loader-btn-view">
                  <AuthLoader fullScreen={false} />
                </div>
              ) : (
                ''
              )}
            </>
          )}
          {questionStatus === 'Completed' ? (
            <>
              <ButtonCustom
                cssClass={`blue-btn my-2 my-md-4 space-no-wrap ${
                  isBtnDisabled() ? 'btn-disabled' : ''
                }`}
                onClick={onDownloadArtifactsButton}
                buttonAction={'Download artifacts button clicked'}
                id={'btn-edit-order-button'}
                disabled={isBtnDisabled() ? true : false}
              >
                <div className="icon-button d-flex flex-justify-center ">
                  <TbFileDownload />
                  <span>Download Artifacts</span>
                </div>
              </ButtonCustom>
              {isFetchingDownloadLinkStarted ? (
                <div className="loader-btn-view">
                  <AuthLoader fullScreen={false} />
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          {questionStatus === 'Pending' ? (
            <ButtonCustom
              cssClass="blue-btn my-2 my-md-4 space-no-wrap"
              onClick={onClickOfCancelOrder}
              buttonAction={'Order cancel button clicked'}
              id={'btn-cancel-orders-button'}
              disabled={viewAsSession && viewAsSession?.user?.email ? true : false}
            >
              <div className="icon-button d-flex flex-justify-center ">
                <MdOutlineCancel />
                <span>Cancel order</span>
              </div>
            </ButtonCustom>
          ) : (
            ''
          )}
        </div>
        {getFileDownloadError && resource === resourceEnum.ARTIFACTS ? (
          <>
            {getFileDownloadError === orderFormErrorStr.commonError ? (
              <div className="mb-3 color-error error-text text-center">
                Something went wrong. Please{' '}
                <a href={`mailto:${labels.supportEmail}`} className="color-error">contact support</a>{' '}
                for help!
              </div>
            ) : (
              <div className="mb-3 color-error error-text text-center">
                {getFileDownloadError}
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default PicotOptions;
