import { labels } from 'constants/labels';
import React from 'react';

function Answer({ answer }) {
  return (
    <div className="d-flex flex-column gap-10">
      <div className="txt size-16 w-700 lh-22 text-primary-1">{labels.ANSWER}</div>
      <div className="txt size-16 w-400 lh-21 text-primary-1">{answer}</div>
    </div>
  );
}

export default Answer;
