import React from 'react';
import { Tooltip } from 'primereact/tooltip';

function CustomTooltip({ content, info, position = 'right', ...props }) {
  return (
    <div className="flex justify-content-center">
      <Tooltip target=".custom-target-icon" {...props} />

      <i
        className="custom-target-icon pi pi-envelope p-text-secondary p-overlay-badge"
        data-pr-tooltip={info}
        data-pr-position={position}
        style={{ cursor: 'pointer', display: 'flex' }}
        onClick={props.onClick ? props.onClick : () => {}}
      >
        {content}
      </i>
    </div>
  );
}

export default CustomTooltip;
