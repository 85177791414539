import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div`
  font-size: 16px;
  line-height: 26px;
  width: 95%;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  } ;
`;

function AiSummaryQuestion({ question }) {
  return question ? (
    <StyledTitle className="txt text-black-900">{question}</StyledTitle>
  ) : null;
}

export default AiSummaryQuestion;
