import React from 'react';
import styled from 'styled-components';

export const StyledTruncatedText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.width + '%' || '100%'};
  display: block;
`;

function TruncatedText({ text, ...props }) {
  return <StyledTruncatedText {...props}>{text}</StyledTruncatedText>;
}

export default TruncatedText;
