import React, { useState, useEffect, useRef } from 'react';
import { ButtonCustom } from 'components/form-input/Button';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { useHistory } from 'react-router-dom';
import TextInput from 'components/form-input/TextInput';
import { useAuth0 } from '@auth0/auth0-react';
import { FaEdit } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomModal from '../components/CustomModal';
import { VscChromeClose } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatyyyymmdd, dateFormatmmddyyyy } from '../utils/dateFormats';
import { converBase64toLink } from '../utils/converBase64toLink';
import {
  updateProfileInfo,
  clearProfileError,
  sendEmailVerification,
  sendPhoneVerification,
  phoneVerification,
  requestGetUserProfile,
} from 'redux/modules/userProfile/actions';
import { setSearchQueryText } from 'redux/modules/orderDetails/actions';
import orderFormErrorStr from 'constants/errorStrings';
import { validateAsText, validateEmail } from 'utils/validations';
import AuthLoader from 'components/AuthLoader';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import OtpInput from 'react-otp-input';
import { COUNTRY_CODE_NAMESPACE } from 'constants/Constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { BsFillPersonFill } from 'react-icons/bs';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { useDebounceEffect } from 'utils/useDebounceEffect';
import { getRawToken } from '../utils/validations';
import { useSessionStorageState } from 'hooks/useSessionStorageState';
import { labels } from 'constants/labels';

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getIdTokenClaims, user } = useAuth0();
  const {
    userData,
    isLoading,
    updateProfileError,
    sendEmailVerificationError,
    sendEmailSuccessMessage,
    phoneVerificationError,
    sendPhoneVerificationError,
    sendPhoneSuccessMessage,
  } = useSelector(({ userProfile }) => userProfile);
  const { orderScopeData } = useSelector(({ orderDetails }) => orderDetails);
  const {
    first_name = '',
    email = '',
    last_name = '',
    profile_photo = '',
    guid = '',
    emails = [],
    birthday = '',
    phones = [],
    institute = {},
  } = userData || {};
  const [birthDate, setDate] = useState(
    birthday ? new Date(dateFormatmmddyyyy(birthday)) : '',
  );

  const profile_converted_pic = converBase64toLink(profile_photo);
  const [imageSrc, setImageSrc] = useState(
    profile_photo ? (profile_converted_pic ? profile_converted_pic : '') : '',
  );
  const [country_code, setCountryCode] = useState(
    user
      ? user[COUNTRY_CODE_NAMESPACE] !== undefined
        ? user[COUNTRY_CODE_NAMESPACE]
        : 'US'
      : 'US',
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [shouldShowFormError, setShouldShowFormError] = useState(false);
  const [ShouldShowBirthdayError, setShouldShowBirthdayError] = useState(false);
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [aspect, setAspect] = useState(1 / 1);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [filePath, setFilePath] = useState('');
  const [viewAsSession, setViewAsSession] = useSessionStorageState({}, 'viewAsSession');
  const isOptionDisabled =
    viewAsSession && viewAsSession && viewAsSession?.user?.email ? true : false;

  useEffect(() => {
    setCountryCode(
      user
        ? user[COUNTRY_CODE_NAMESPACE] !== undefined
          ? user[COUNTRY_CODE_NAMESPACE]
          : 'US'
        : 'US',
    );
    dispatch(setSearchQueryText({}));
  }, [user]);

  useEffect(() => {
    if (phones[0]?.phone) {
      setPhoneNumber(phones[0]?.phone);
    }
  }, [phones]);

  let initialState = {
    firstName: {
      value: first_name ? first_name : '',
      isValid: true,
      errorText: orderFormErrorStr.firstName,
    },
    lastName: {
      value: last_name ? last_name : '',
      isValid: true,
      errorText: orderFormErrorStr.lastName,
    },
    emailIdObj: emails ? emails : [email],
    emailIds: {
      value: [],
      isValid: true,
      errorText: orderFormErrorStr.email,
    },
    newEmail: {
      value: '',
      isValid: true,
      errorText: orderFormErrorStr.email,
    },
  };

  const [formState, setFormState] = useState(initialState);
  const [imageBase64, setImageBase64] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showPhoneSuccessModal, setShowPhoneSuccessModal] = useState(false);
  const [otp, setOtp] = useState('');
  const imgRef = useRef(null);
  const modal1Ref = useRef(null);
  const modal2Ref = useRef(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [isSetCroppedImageToProfile, setCroppedImageToProfile] = useState(false);
  const [isOutputImage, setIsOutputImage] = useState(false);
  const [output, setOutput] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });

  const handleDateChange = (date) => {
    setDate(date);
  };
  useEffect(() => {
    dispatch(clearProfileError());
  }, [dispatch]);

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      emailIdObj: emails ? emails : [email],
      firstName: { ...formState.firstName, value: first_name },
      lastName: { ...formState.lastName, value: last_name },
    }));
  }, [userData]);

  const validateBirthdate = (date) => {
    if (date !== '' && date !== null) {
      var settedDate = new Date(date);
      var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      if (settedDate > currentDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const validatePhoneNumber = () => {
    if (phoneNumber) {
      const phone = parsePhoneNumberFromString(phoneNumber);
      if (phone) {
        if (phone.nationalNumber.length !== 10) {
          setShouldShowFormError(true);
          return false;
        } else {
          setShouldShowFormError(false);
          return true;
        }
      } else {
        setShouldShowFormError(true);
        return false;
      }
    } else {
      setShouldShowFormError(false);
      return true;
    }
  };

  const onBlurOfDate = (date) => {
    if (date && !validateBirthdate(date)) {
      setShouldShowBirthdayError(true);
      setDate('');
    }
  };

  const onFocusOfDate = () => {
    setShouldShowBirthdayError(false);
  };

  //generic handles
  const handleInputFocus = (name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], isValid: true },
    }));
  };

  //generic handles
  const handleInputChange = (value, name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], value: value },
    }));
  };

  const handleFirstNameBlur = () => {
    setFormState((formState) => ({
      ...formState,
      firstName: {
        ...formState.firstName,
        isValid: validateAsText(formState.firstName.value),
      },
    }));
  };

  const handleLastNameBlur = () => {
    setFormState((formState) => ({
      ...formState,
      lastName: {
        ...formState.lastName,
        isValid: validateAsText(formState.lastName.value),
      },
    }));
  };

  //check of duplicate email
  const isDuplicateEmail = (newEmail) => {
    return formState.emailIds.value.includes(newEmail);
  };

  //email handles
  const handleEmailBlur = () => {
    if (!validateEmail(formState.newEmail.value)) {
      setFormState((formState) => ({
        ...formState,
        newEmail: {
          ...formState.newEmail,
          isValid: false,
          errorText: orderFormErrorStr.email,
        },
      }));
    } else if (isDuplicateEmail(formState.newEmail.value)) {
      setFormState((formState) => ({
        ...formState,
        newEmail: {
          ...formState.newEmail,
          isValid: false,
          errorText: 'Email is already present!',
        },
      }));
    } else {
      setFormState((formState) => ({
        ...formState,
        newEmail: {
          ...formState.newEmail,
          isValid: true,
        },
      }));
    }
  };

  const isFormValid = () => {
    return (
      validateAsText(formState.firstName.value) &&
      validateAsText(formState.lastName.value) &&
      validatePhoneNumber() &&
      validateBirthdate(birthDate)
    );
  };

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function canvasPreview(image) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    var x = document.createElement('IMG');
    x.setAttribute('src', imageSrc);

    if (!ctx) {
      throw new Error('No 2d context');
    }
    canvas.width = Math.floor(completedCrop.width);
    canvas.height = Math.floor(completedCrop.height);

    ctx.save();
    ctx.drawImage(
      image,
      completedCrop.x,
      completedCrop.y,
      image.naturalWidth / 2,
      image.naturalHeight / 2,
      0,
      0,
      completedCrop.width,
      completedCrop.height,
    );
    ctx.restore();

    const base64Image = canvas.toDataURL();
    setIsOutputImage(true);

    if (isSetCroppedImageToProfile) {
      setOutput(base64Image);
      setImageSrc(base64Image);
      var strImage = base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
      setImageBase64(strImage);
    } else {
      setCroppedImageToProfile(false);
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        canvasPreview(imgRef.current);
      }
    },
    100,
    [completedCrop],
  );

  const profileImage = (name, profilePicture) => {
    if (profilePicture || imageSrc) {
      return (
        <>
          {output === null ? (
            <img
              src={
                profilePicture !== null && isSetCroppedImageToProfile
                  ? profilePicture
                  : imageSrc
              }
              alt={name}
            />
          ) : (
            <img src={output} alt={name} />
          )}
        </>
      );
    } else {
      return (
        <div className="d-flex flex-justify-center flex-items-center no-image">
          {name.trim() !== '' ? (
            <span>{name.charAt(0).toUpperCase()} </span>
          ) : (
            <span>
              <BsFillPersonFill />
            </span>
          )}
        </div>
      );
    }
  };

  const showAddEmailModal = () => {
    setShowModal(true);
    setFormState((formState) => ({
      ...formState,
      newEmail: {
        ...formState.newEmail,
        value: '',
        isValid: true,
      },
    }));
  };

  const showVerifyPhoneModal = () => {
    dispatch(clearProfileError());
    setOtp('');
    setShowPhoneModal(true);
  };

  const onClickOfClose = () => {
    setShowModal(false);
  };

  const onClickOfCloseCropImageModal = () => {
    setProfilePic(null);
    setShowImageModal(false);
    setIsOutputImage(false);
    setCroppedImageToProfile(false);
  };

  const onClickOfClosePhoneModal = () => {
    setOtp('');
    setShowPhoneModal(false);
  };

  const handleImageSelect = (e) => {
    if (e.target.files[0]) {
      setProfilePic(URL.createObjectURL(e.target.files[0]));
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function () {
        var base64data = reader.result;
        var strImage = base64data.replace(/^data:image\/[a-z]+;base64,/, '');
        setImageBase64(strImage);
      };
    } else {
      setShowImageModal(false);
    }
  };

  const checkEmpty = (value) => {
    return value === undefined || value === null || value === '';
  };

  const checkAndAddChangedInputs = () => {
    var paramObj = {};
    if (formState.firstName.value !== first_name) {
      paramObj.first_name = formState.firstName.value;
    }
    if (formState.lastName.value !== last_name) {
      paramObj.last_name = formState.lastName.value;
    }
    if (
      dateFormatyyyymmdd(birthDate ? birthDate : new Date()) !==
      dateFormatyyyymmdd(birthday)
    ) {
      paramObj.birthday = birthDate ? dateFormatyyyymmdd(birthDate) : null;
    }
    if (phoneNumber !== phones[0]?.phone) {
      if (checkEmpty(phoneNumber)) {
        paramObj.phone_numbers = [];
      } else {
        paramObj.phone_numbers = [phoneNumber];
      }
    }
    if (imageBase64 && profile_photo !== imageBase64) {
      paramObj.profile_photo = imageBase64;
    }
    paramObj.emails = formState.emailIds.value;
    return paramObj;
  };

  const resendEmailVerification = async (email) => {
    const token = await getIdTokenClaims();
    dispatch(
      sendEmailVerification({
        params: { email: email },
        token: getRawToken(token),
      }),
    );
  };

  const saveProfileInfo = async () => {
    const token = await getIdTokenClaims();
    if (isFormValid()) {
      var param = checkAndAddChangedInputs();
      dispatch(
        updateProfileInfo({
          params: param,
          userGuid: guid,
          token: getRawToken(token),
          successCallback: showsSuccessModal,
        }),
      );
    } else {
      validatePhoneNumber();
      if (birthDate || !validateBirthdate(birthDate)) {
        setShouldShowBirthdayError(true);
      }
      if (!validateAsText(formState.firstName.value)) {
        setFormState((formState) => ({
          ...formState,
          firstName: {
            ...formState.firstName,
            isValid: false,
          },
        }));
      }
      if (!validateAsText(formState.lastName.value)) {
        setFormState((formState) => ({
          ...formState,
          lastName: {
            ...formState.lastName,
            isValid: false,
          },
        }));
      }
    }
  };

  const navigateToDashboard = () => {
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };

  const showsSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const getProfileData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestGetUserProfile({
        token: getRawToken(token),
      }),
    );
  };
  const onClickOfCloseSuccessModal = () => {
    setShowSuccessModal(false);
    getProfileData();
  };

  const addEmail = () => {
    if (validateEmail(formState.newEmail.value)) {
      if (!isDuplicateEmail(formState.newEmail.value)) {
        var newEmailArray = formState.emailIds.value;
        newEmailArray.push(formState.newEmail.value);
        setFormState((formState) => ({
          ...formState,
          emailIds: { value: newEmailArray },
        }));
        setShowModal(false);
      }
    }
  };

  const verifyPhoneOtp = async () => {
    if (otp.length === 6) {
      setShowPhoneModal(false);
      dispatch(clearProfileError());
      const token = await getIdTokenClaims();
      dispatch(
        phoneVerification({
          params: { phone: phoneNumber, token: otp },
          token: getRawToken(token),
          onVerifyPhoneOtp: onVerifyPhoneOtp,
          onVerifyPhoneOtpError: showPhoneVerificationModal,
        }),
      );
    }
  };

  const onSendPhoneVerification = async () => {
    setShowPhoneModal(false);
    dispatch(clearProfileError());
    const token = await getIdTokenClaims();
    dispatch(
      sendPhoneVerification({
        params: { phone: phoneNumber },
        token: getRawToken(token),
        onSendPhoneVerificationCallback: showPhoneVerificationModal,
      }),
    );
  };

  const showPhoneVerificationModal = () => {
    setShowPhoneModal(true);
  };

  const onVerifyPhoneOtp = () => {
    setShowPhoneSuccessModal(true);
  };

  const onClickOfClosePhoneSuccessModal = () => {
    setShowPhoneSuccessModal(false);
    getProfileData();
  };

  useEffect(() => {
    if (emails) {
      var newEmailArray = [];
      emails.forEach((emailObj) => {
        if (emailObj.primary) {
          setPrimaryEmail(emailObj.email);
        }
        newEmailArray.push(emailObj.email);
      });
      setFormState((formState) => ({
        ...formState,
        emailIds: { value: newEmailArray },
      }));
    }
  }, [userData]);

  const cancelEmail = (email) => {
    formState.emailIds.value.forEach((id) => {
      if (id === email) {
        var newArray = formState.emailIds.value;
        const indexOfemail = newArray.indexOf(email);
        if (indexOfemail > -1) {
          newArray.splice(indexOfemail, 1);
          setFormState((formState) => ({
            ...formState,
            emailIds: { value: newArray },
          }));
        }
      }
    });
  };

  const checkEmailVerificationStatus = (email) => {
    var status = '';
    for (let i = 0; i < formState.emailIdObj.length; i++) {
      if (formState.emailIdObj[i].email === email) {
        status = formState.emailIdObj[i].verification_status;
      }
    }
    return status;
  };

  const showEmails = () => {
    return (
      <div className="inputText">
        {formState.emailIds.value.map((email, index) => {
          let status = checkEmailVerificationStatus(email);
          return (
            <div className="my-md-1 my-2 d-flex flex-justify-between" key={index}>
              <div className="flex-self-center d-md-flex justify-content-left">
                <div className="flex-self-center">{email}</div>
                <div className="flex-self-center">
                  {status === 'Pending' ? (
                    <div className="font-bold pl-1 d-md-none d-block color-primary">
                      (Pending)
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="d-md-none d-block">
                  {status === 'Expired' ? (
                    <ButtonCustom
                      cssClass="pending-status"
                      onClick={() => resendEmailVerification(email)}
                      buttonAction={'Email resend verification button clicked'}
                      id={'bt-resend-email-verification1-button'}
                    >
                      Resend Link
                    </ButtonCustom>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="d-flex flex-self-center">
                <div className="d-flex flex-self-center d-md-block d-none">
                  {status === 'Expired' ? (
                    <ButtonCustom
                      cssClass="pending-status"
                      onClick={() => resendEmailVerification(email)}
                      buttonAction={'Email resend verification button clicked'}
                      id={'bt-resend-email-verification2-button'}
                    >
                      Resend Link
                    </ButtonCustom>
                  ) : (
                    ''
                  )}
                  {status === 'Pending' ? (
                    <div className="pending-status d-md-block d-none">Pending</div>
                  ) : (
                    ''
                  )}
                </div>
                {primaryEmail !== email ? (
                  <div className="d-flex flex-self-center">
                    <div className="flex-self-center">
                      <ButtonCustom
                        cssClass="cancel-email-btn d-flex flex-items-center"
                        onClick={() => cancelEmail(email)}
                        buttonAction={'Remove email clicked'}
                        id={'btn-remove-email-button'}
                      >
                        <VscChromeClose />
                      </ButtonCustom>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modal1Ref.current && !modal1Ref.current.contains(event.target)) {
      onClickOfCloseSuccessModal();
    }
    if (modal2Ref.current && !modal2Ref.current.contains(event.target)) {
      onClickOfClosePhoneSuccessModal();
    }
  };

  const handleChange = (otp) => setOtp(otp);

  return (
    <div className="home-container account-container mb-5">
      <div className="account-page">
        <CustomModal
          isOpen={showPhoneModal}
          handleClose={onClickOfClosePhoneModal}
          cssClass="white-overlay"
          modalname="Phone verification modal"
        >
          <div className="email-modal p-md-5 p-3">
            <div className={`${isLoading ? 'visibility-hidden' : ''}`}>
              <ButtonCustom
                cssClass="close-btn"
                onClick={() => onClickOfClosePhoneModal()}
                buttonAction={'Closed phone verification Modal'}
                id={'btn-close-phone-verification-button'}
              >
                <VscChromeClose />
              </ButtonCustom>
              <div className="profile-sub-header mt-1 mb-5">Enter Verification Code</div>
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                separator={<span className="visibility-hidden otp-seperator">X</span>}
                inputStyle="otp-input"
                isInputNum={true}
                isDisabled={phones[0]?.verification_status === 'Expired' ? true : false}
              />
              {phones[0]?.verification_status === 'Expired' ? (
                <div className="mt-1 small">
                  One-Time Password is expired, click on resend
                </div>
              ) : (
                ''
              )}
              <ButtonCustom
                cssClass="mt-5 d-block resend-link"
                onClick={() => onSendPhoneVerification()}
                buttonAction={'Resend one time password for phone clicked'}
                id={'btn-resend-phone-otp-button'}
              >
                Resend One-Time Password
              </ButtonCustom>
              <ButtonCustom
                cssClass={`security-btn mt-2 ${otp.length === 6 ? '' : 'btn-disabled'}`}
                onClick={verifyPhoneOtp}
                buttonAction={'Verify one time password cliked'}
                actionLabel={'Phone verification'}
                id={'btn-verifiy-phone-one-time-password-button'}
              >
                Verify One-Time Password
              </ButtonCustom>
            </div>
            {!!phoneVerificationError ? (
              <div className="color-error error-text">{phoneVerificationError}</div>
            ) : (
              ''
            )}
            {!!sendPhoneVerificationError ? (
              <div className="color-error error-text">{sendPhoneVerificationError}</div>
            ) : (
              ''
            )}
            {!!sendPhoneSuccessMessage ? (
              <div className="color-success error-text">{sendPhoneSuccessMessage}</div>
            ) : (
              ''
            )}
          </div>
        </CustomModal>
        <CustomModal
          isOpen={showModal}
          handleClose={onClickOfClose}
          cssClass="white-overlay"
          modalname="New email modal"
        >
          <div className="email-modal p-5">
            <ButtonCustom
              cssClass="close-btn"
              onClick={() => onClickOfClose()}
              buttonAction={'Close add new email modal'}
              id={'btn-close-new-email-button'}
            >
              <VscChromeClose />
            </ButtonCustom>
            <div className="profile-sub-header mt-4 mb-2">Add Contact Email</div>
            <TextInput
              placeholder="Email"
              isFloatingLabel={false}
              value={formState.newEmail.value}
              isError={!formState.newEmail.isValid}
              errorText={formState.newEmail.errorText}
              onChange={(e) => handleInputChange(e.target.value, 'newEmail')}
              onFocus={() => handleInputFocus('newEmail')}
              onBlur={handleEmailBlur}
              id={'textinput-email'}
            />
            <div className="verification mt-2">Verification needed</div>
            <ButtonCustom
              cssClass={`security-btn mt-5 ${
                validateEmail(formState.newEmail.value) &&
                !isDuplicateEmail(formState.newEmail.value)
                  ? ''
                  : 'btn-disabled'
              }`}
              onClick={addEmail}
              buttonAction={'Add new email button clicked'}
              actionLabel={'New email added'}
              id={'btn-new-email-add-button'}
            >
              Add
            </ButtonCustom>
          </div>
        </CustomModal>
        <CustomModal
          isOpen={showSuccessModal}
          handleClose={onClickOfCloseSuccessModal}
          cssClass="white-overlay"
          modalname="Profile updated success modal"
        >
          <div
            ref={modal1Ref}
            className="email-modal p-3 delete-modal text-center color-white d-flex flex-justify-between bg-delete-success"
          >
            <div className="small">Profile info updated successfully!</div>
            <ButtonCustom
              cssClass="close-btn color-white"
              onClick={() => onClickOfCloseSuccessModal()}
              buttonAction={'Modal close button clicked'}
              id={'btn-modal-closed-button'}
            >
              <VscChromeClose />
            </ButtonCustom>
          </div>
        </CustomModal>
        <CustomModal
          isOpen={showPhoneSuccessModal}
          handleClose={onClickOfClosePhoneSuccessModal}
          cssClass="white-overlay"
          modalname="Phone verification success Modal"
        >
          <div
            ref={modal2Ref}
            className="email-modal p-3 delete-modal text-center color-white d-flex flex-justify-between bg-delete-success"
          >
            <div className="small">Phone verified successfully!</div>
            <ButtonCustom
              cssClass="close-btn color-white"
              onClick={() => onClickOfClosePhoneSuccessModal()}
              buttonAction={'Modal closed button clicked'}
              id={'btn-phone-verification-closed-button'}
            >
              <VscChromeClose />
            </ButtonCustom>
          </div>
        </CustomModal>
        <div className="d-flex d-md-block">
          <ButtonCustom
            cssClass="profile-back-button"
            onClick={() => navigateToDashboard()}
            buttonAction="Back button clicked"
            id={'btn-back-button'}
          >
            <HiOutlineArrowLeft />
          </ButtonCustom>
          <div className="page-title my-3 my-md-4">Settings</div>
        </div>

        <div>
          {isLoading ? (
            <div className="position-relative">
              <AuthLoader fullScreen={false} />
            </div>
          ) : (
            <div>
              <div className="avatar h6 lh-condensed-ultra d-flex mb-6">
                <div className="upload-btn-wrapper">
                  <button className="edit-profile">
                    <FaEdit />
                  </button>

                  <input
                    type="file"
                    name="profilePhoto"
                    value={filePath}
                    onChange={(e) => {
                      handleImageSelect(e);
                      setOutput(null);
                      setShowImageModal(true);
                    }}
                    onClick={() => {
                      setFilePath('');
                    }}
                    accept=".jpg,.jpeg,.png"
                    id={'input-profile-photo'}
                    disabled={isOptionDisabled}
                  />
                </div>
                {profileImage(first_name + '' + last_name, profilePic)}
              </div>

              <CustomModal
                isOpen={showImageModal}
                handleClose={onClickOfCloseCropImageModal}
                cssClass="white-overlay"
                modalname="Profile image crop modal"
              >
                <div className="crop-image-modal p-5">
                  <ButtonCustom
                    cssClass="close-btn"
                    onClick={() => onClickOfCloseCropImageModal()}
                    buttonAction={'Image crop modal close button clicked'}
                    id={'btn-image-crop-modal-close-button'}
                  >
                    <VscChromeClose />
                  </ButtonCustom>
                  <div className="crop-image-header mt-4 mb-6">
                    Crop image before setting it to profile
                  </div>
                  <div className="d-flex flex-justify-center crop-image my-1">
                    <div>
                      <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(pixel) => setCompletedCrop(pixel)}
                        aspect={1 / 1}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop image"
                          src={profilePic}
                          onLoad={(e) => onImageLoad(e)}
                        />
                      </ReactCrop>
                    </div>
                  </div>
                  <ButtonCustom
                    cssClass={`security-btn mt-5 ${isOutputImage ? '' : 'btn-disabled'}`}
                    onClick={() => {
                      setCroppedImageToProfile(true);
                      setShowImageModal(false);
                    }}
                    buttonAction={'Crop profile image button clicked'}
                    actionLabel={'Profile image cropped'}
                    id={'btn-crop-image-button'}
                  >
                    Crop
                  </ButtonCustom>
                </div>
              </CustomModal>

              <div className="mt-1"></div>
              <div>
                <div className="profile-sub-header my-4">Basic Info</div>
                <div className="d-md-flex flex-md-justify-between">
                  <div className="mb-4 w-48">
                    <TextInput
                      label="First Name"
                      placeholder="First Name"
                      isFloatingLabel={false}
                      value={formState.firstName.value}
                      isError={!formState.firstName.isValid}
                      errorText={formState.firstName.errorText}
                      onChange={(e) => handleInputChange(e.target.value, 'firstName')}
                      onFocus={() => handleInputFocus('firstName')}
                      onBlur={handleFirstNameBlur}
                      id={'textinput-first-name'}
                      disabled={isOptionDisabled}
                    />
                  </div>
                  <div className="mb-4 w-48">
                    <TextInput
                      label="Last Name"
                      placeholder="Last Name"
                      isFloatingLabel={false}
                      value={formState.lastName.value}
                      isError={!formState.lastName.isValid}
                      errorText={formState.lastName.errorText}
                      onChange={(e) => handleInputChange(e.target.value, 'lastName')}
                      onFocus={() => handleInputFocus('lastName')}
                      onBlur={handleLastNameBlur}
                      id={'textinput-last-name'}
                      disabled={isOptionDisabled}
                    />
                  </div>
                </div>
                <div className="d-md-flex flex-md-justify-between">
                  <div className="mb-4 w-48">
                    <div className="input-label mb-1">Birthday</div>
                    <DatePicker
                      selected={birthDate}
                      onChange={handleDateChange}
                      placeholderText="mm/dd/yyyy"
                      onBlur={(e) => onBlurOfDate(e.target.value)}
                      className={`${ShouldShowBirthdayError ? 'border-error' : ''}`}
                      onFocus={() => onFocusOfDate()}
                      id={'datepicker-birthdate'}
                      disabled={isOptionDisabled}
                    />
                    {ShouldShowBirthdayError ? (
                      <div className="color-error error-text">
                        {orderFormErrorStr.birthDate}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="mb-4 w-48">
                    <div className="input-label mb-1">Organization</div>
                    <TextInput
                      isFloatingLabel={false}
                      value={!institute?.is_global ? 'Other - ' : '' + institute?.name}
                      disabled={true}
                      id={'textinput-organization'}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="profile-sub-header my-4">Contact Info</div>
                <div className="mb-4">
                  <div className="input-label mb-1">Email</div>
                  {showEmails()}
                  {!!sendEmailSuccessMessage ? (
                    <div className="color-success error-text">
                      {sendEmailSuccessMessage}
                    </div>
                  ) : (
                    ''
                  )}
                  {!!sendEmailVerificationError ? (
                    <div className="color-error error-text">
                      {sendEmailVerificationError}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <ButtonCustom
                  cssClass="security-btn mb-4"
                  onClick={() => showAddEmailModal()}
                  buttonAction={'Add new email button clicked'}
                  id={'btn-add-new-email-button'}
                  disabled={isOptionDisabled}
                >
                  Add Another Email
                </ButtonCustom>
                <div className="mb-4 w-48">
                  <div className="input-label mb-1">Phone Number</div>
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry={country_code}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    onCountryChange={(country) => setCountryCode(country)}
                    onBlur={(e) => validatePhoneNumber()}
                    disabled={isOptionDisabled}
                  />

                  {shouldShowFormError ? (
                    <div className="color-error error-text">
                      {orderFormErrorStr.phone_number}
                    </div>
                  ) : (
                    ''
                  )}
                  {phones[0]?.verification_status !== 'Verified' && phones[0]?.phone ? (
                    <div className="mt-1">Verification Pending</div>
                  ) : (
                    ''
                  )}
                </div>
                {phones[0]?.verification_status !== 'Verified' && phones[0]?.phone ? (
                  <ButtonCustom
                    cssClass="security-btn"
                    onClick={() => showVerifyPhoneModal()}
                    buttonAction={'Verify phone number button clicked'}
                    id={'btn-verify-phone-number-button'}
                  >
                    Verify Phone Number
                  </ButtonCustom>
                ) : (
                  ''
                )}
                <div className="mb-4"></div>
              </div>
              <ButtonCustom
                cssClass={'gradient-btn profile-btn mt-md-0 mt-3'}
                onClick={() => saveProfileInfo()}
                buttonAction={'Save user profile information button clicked'}
                actionLabel={'Profile information saved'}
                id={'btn-save-profile-button'}
                disabled={isOptionDisabled}
              >
                Save
              </ButtonCustom>
              {!!updateProfileError ? (
                <>
                  {updateProfileError === orderFormErrorStr.commonError ? (
                    <div className="color-error error-text">
                      Something went wrong. Please{' '}
                      <a href={`mailto:${labels.supportEmail}`} className="color-error">contact support</a>{' '}
                      for help!
                    </div>
                  ) : (
                    <div className="color-error error-text">
                      {updateProfileError}
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Profile;
