import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from '../../pages/order/OrderPageContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Strings } from 'constants/Strings';
import { GroupMultiSelect } from 'components/GroupMultiSelect';
import CustomTooltip from 'components/OverlayTooltip/CustomTooltip';
import { ImInfo } from 'react-icons/im';

function DatasourceOptions() {
  const location = useLocation();
  const { pathname } = location;
  const orderTypePath = pathname?.split('/')[2];
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const { datasetList } = useSelector(({ userSubscription }) => userSubscription);
  const { ordersPageData, dispatchOrderPageAction, isReorder } = useOrdersPagedata();
  const { errorsObject, selectedDataSourceOption } = ordersPageData;
  const { data_source_object } = singleOrderData || {};

  useEffect(() => {
    if (isReorder && data_source_object) {
      dispatchOrderPageAction({
        type: 'order/setselectedDataSourceOption',
        payload: [{ ...data_source_object }],
      });
    }
  }, [isReorder, data_source_object]);

  const groupedItemTemplate = (option) => {
    return (
      <div className="d-flex align-items-center gap-5">
        {option.name}
        <CustomTooltip content={<ImInfo />} info={option?.description} />
      </div>
    );
  };

  function handleChange(e) {
    dispatchOrderPageAction({
      type: 'order/setselectedDataSourceOption',
      payload: e.value,
    });

    if (e.value.length === 0) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'dataSourceSelectionError',
          error: Strings.dataSourceSelectionError,
        },
      });
    }

    if (e.value.length && errorsObject.dataSourceSelectionError) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'dataSourceSelectionError',
          error: '',
        },
      });
    }
  }

  return (
    <div
      className={`data-source-container position-relative ${
        singleOrderData?.guid &&
        singleOrderData?.status === 'Pending' &&
        orderTypePath === 'edit'
          ? // orderFormData?.isEditOrder
            'disabled-data'
          : ''
      }`}
    >
      <div className="advanced-setting-title mb-2">Data Sources</div>

      <div className="mb-2 sub-heading-text">
        To learn more about our data sources,{' '}
        <a className="" href={'mailto:support@atroposhealth.com'} id={'email-click-here'}>
          click here
        </a>
      </div>

      <GroupMultiSelect
        placeholder="Select Answer"
        value={selectedDataSourceOption}
        options={datasetList || []}
        optionLabel="name"
        optionGroupLabel="name"
        optionGroupChildren="data_sources"
        onChange={handleChange}
        invalid={errorsObject.dataSourceSelectionError ? true : false}
        disabled={
          singleOrderData?.guid &&
          singleOrderData?.status === 'Pending' &&
          orderTypePath === 'edit'
        }
        itemTemplate={groupedItemTemplate}
        dropdownname="PG Dataset"
      />
      {errorsObject.dataSourceSelectionError && (
        <div className="color-error error-text">
          {errorsObject.dataSourceSelectionError}
        </div>
      )}
    </div>
  );
}

export default DatasourceOptions;
