import React, { useEffect, useState, useRef, useCallback } from 'react';
import SearchForm from './SearchForm';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PrognostorgramOrderDetails from 'components/PrognostorgramOrderDetails';
import PicotOptions from 'components/PicotOptions';
import Onboarding from './Onboarding';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getOrderDetails,
  getOrderStatusCount,
  setOrderFormData,
  clearOrderFormData,
  deleteOrder,
  setOrderScopeData,
  setOrderIndexData,
  resetOrderIndexData,
  setSearchQueryText,
  resetChartrwdErrors,
  setMotivation,
  stopBackgroundSync,
} from 'redux/modules/orderDetails/actions';
import {
  clearProfileError,
  sendEmailVerification,
  updateProfileInfo,
  setVideoModalDisplay,
} from 'redux/modules/userProfile/actions';
import AuthLoader from '../components/AuthLoader';
import ErrorBoundaryModal from '../components/ErrorBoundaryModal';
import { ErrorBoundary } from 'react-error-boundary';
import { dateFormat } from '../utils/dateFormats';
import { ButtonSecondary, ButtonLink } from 'components/form-input/Button';
import PersonalNoOrder from 'components/PersonalNoOrder';
import NoOrder from 'components/NoOrder';
import OrderEmptyIcon from '../assets/images/NoResults.png';
import {
  AccordionWithHeader,
  AccordionNode,
  AccordionHeader,
  AccordionPanel,
} from 'react-accordion-with-header';
import { HiOutlineChevronDown } from 'react-icons/hi';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import DashboardHeader from '../components/DashboardHeader';
import statusHeaderEnum from '../constants/enum/statusHeaderEnum';
import orderScopeEnum from '../constants/enum/orderScopeEnum';
import CustomModal from '../components/CustomModal';
import { VscChromeClose } from 'react-icons/vsc';
import { ButtonCustom } from 'components/form-input/Button';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getRawToken } from '../utils/validations';
import { QUESTION_TEXT_SIZE } from 'constants/Constants';
import {
  setSpecialtyText,
  getSpecialtyList,
  getorderByGuid,
  resetSingleOrderData,
  resetUploadDocumentData,
  resetDownloadLink,
  resetPrognostogramPdf,
  setChatrwdLoaderCloseClicked,
  setUploadedFile,
  clearUploadDocumentsList,
} from 'redux/modules/orderDetails/actions';
import { useMixpanel } from 'react-mixpanel-browser';
import UserUnverifiedTemplate from './UserUnverifiedTemplate';
import TutorialVideoModal from '../components/TutorialVideoModal';
import { ORDER_PAGINATION_SIZE } from '../constants/Constants';
import {
  resetSubscriptionError,
  resetStripeRedirectUrl,
} from 'redux/modules/userSubscription/actions';
import { RiErrorWarningFill } from 'react-icons/ri';
import LogOutUtil from '../utils/LogOutUtil';
import resourceEnum from '../constants/enum/resourceEnum';
import onBoardingStepsEnum from '../constants/enum/onBoardingStepsEnum';
import { readNotification } from 'redux/modules/notifications/actions';
import { handleOrderDetailModal } from 'redux/modules/orderDetails/actions';
import OrderdetailModal from 'components/OrderdetailModal';
import CancelOrderModal from 'components/CancelOrderModal';
import { labels } from 'constants/labels';
import orderFormErrorStr from 'constants/errorStrings';
import { getCaseOrSearchID } from 'utils/getCaseOrSearchID';
import { useSessionStorageState } from 'hooks/useSessionStorageState';
import ViewAsErrorTemplate from './ViewAsErrorTemplate';

const Home = ({ props }) => {
  const [viewAsSession, setViewAsSession] = useSessionStorageState({}, 'viewAsSession');
  const { getIdTokenClaims } = useAuth0();
  const { logOutUser } = LogOutUtil();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const mixpanel = useMixpanel();
  const {
    userProfileError,
    userData,
    isFetchProfileCompleted,
    sendEmailVerificationError,
    sendEmailSuccessMessage,
    displayVideoModal,
    isLoading,
  } = useSelector(({ userProfile }) => userProfile);
  const { sessionData, subscriptionInitiationError, isShowSubscriptionLoading } =
    useSelector(({ userSubscription }) => userSubscription);
  const {
    orderData,
    isOrderLoading,
    questionData,
    getOrderError,
    orderStatusCountData,
    pdfFile,
    deleteOrderError,
    waitingForOrders,
    pageNumber,
    hasMoreOrders,
    orderScopeData,
    orderListCount = 0,
    orderIndexData,
    singleOrderData,
    getOrderDetailError,
    isgettingOrderDetails,
    fileDowloadLink,
    resource,
    isNotificationModalOpen,
    currentOrderMotivation,
  } = useSelector(({ orderDetails }) => orderDetails);
  const { notificationGuid } = useSelector(({ notifications }) => notifications);
  let { tabID, guid } = useParams();
  const [orderFilter, setOrderFilter] = useState(statusHeaderEnum.ALL);
  const [ordersBy, setOrderBy] = useState('newest-first');
  const [specialtyTag, setSpecialtyTag] = useState('');
  const [dataSourceTag, setDataSourceTag] = useState('');
  const [motivationTag, setMotivationTag] = useState('');
  const [isDisplayOrder, setDisplayOrder] = useState(false);
  const [tabIndex, setTabIndex] = useState(
    orderScopeData?.scopeTabIndex ? orderScopeData.scopeTabIndex : 0,
  );
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState(onBoardingStepsEnum.PERSONAL_INFO);
  const [showStripeErrorModal, setShowStripeErrorModal] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [clickedTitleKey, setClickedTitleKey] = useState(null);
  const [isAuthorised, setAuthorised] = useState(true);
  const [isAuthorisedChecked, setAuthorisedChecked] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');

  const [orderScope, setOrderScope] = useState(
    orderScopeData?.orderScope
      ? orderScopeData.orderScope
      : tabID
      ? tabID
      : orderScopeEnum.PERSONAL,
  );
  const [orderPageNumber, setOrderPageNumber] = useState(pageNumber ? pageNumber : 1);
  const { first_name = '', email = '', last_name = '', institute = {} } = userData || {};
  const [orderIndex, setOrderIndex] = useState(
    orderIndexData?.index !== undefined ? orderIndexData?.index : null,
  );
  const [isOrderOpednedFromPortal, setIsOrderOpednedFromPortal] = useState(
    orderIndexData?.isOpened !== undefined ? orderIndexData?.isOpened : false,
  );
  const [scrollElement, setScrollElement] = useState(null);
  useEffect(() => {
    dispatch(clearOrderFormData());
    dispatch(setSearchQueryText({}));
    dispatch(setChatrwdLoaderCloseClicked({ clicked: false }));
  }, [dispatch]);

  useEffect(() => {
    if (
      fileDowloadLink !== undefined &&
      fileDowloadLink !== '' &&
      resource === resourceEnum.ARTIFACTS
    ) {
      window.open(fileDowloadLink);
      dispatch(resetDownloadLink());
    }
  }, [fileDowloadLink]);

  // useEffect(() => {
  //   if (pdfFile !== undefined && pdfFile !== '') {
  //     // window.open(pdfFile);
  //     dispatch(resetPrognostogramPdf());
  //   }
  // }, [pdfFile]);

  useEffect(() => {
    if (sessionData) {
      if (sessionData?.redirect_url) {
        window.location.href = sessionData?.redirect_url;
        dispatch(resetStripeRedirectUrl());
      }
    }
  }, [sessionData]);

  useEffect(() => {
    if (guid && !isOrderOpednedFromPortal) {
      getOrder();
    }
  }, [guid, isOrderOpednedFromPortal]);

  const getOrder = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getorderByGuid({
        token: getRawToken(token),
        guid: guid,
      }),
    );
  };

  const redirectToDashboard = (index = tabIndex, guid = '') => {
    var url = '';
    url = index === 1 ? urlConstantsEnum.LIBRARY_DASHBOARD : urlConstantsEnum.DASHBOARD;
    url += guid ? '/' + guid : '';
    history.push(url);
    if (isNotificationModalOpen) {
      dispatch(handleOrderDetailModal(false));
    }
  };

  useEffect(() => {
    if (
      guid !== undefined &&
      orderIndex === null &&
      !isOrderOpednedFromPortal &&
      singleOrderData
    ) {
      setDisplayOrder(true);
    } else {
      setDisplayOrder(false);
    }
  }, [guid, orderIndex, isOrderOpednedFromPortal, singleOrderData]);

  const OnClickOfOrderClose = (isOwner) => {
    var tab = isOwner !== undefined && isOwner === true ? 0 : 1;
    var scope = tab === 1 ? orderScopeEnum.LIBRARY : orderScopeEnum.PERSONAL;
    setTabIndex(tab);
    var element = document.getElementsByClassName('react-tabs__tab');
    element[tab]?.click();
    dispatch(setOrderScopeData({ scopeTabIndex: tab, orderScope: scope }));
    setDisplayOrder(false);
    dispatch(resetSingleOrderData({}));
    redirectToDashboard(tab);
  };

  useEffect(() => {
    if (userData) {
      userData.emails?.forEach((emailObj) => {
        if (emailObj.email === userData.last_login_email) {
          setVerificationStatus(emailObj.verification_status);
          if (emailObj.verification_status === 'Verified') {
            setAuthorised(true);
          } else {
            setAuthorised(false);
          }
          setAuthorisedChecked(true);
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    if (!!subscriptionInitiationError) {
      setShowStripeErrorModal(true);
    }
  }, [subscriptionInitiationError]);

  useEffect(() => {
    if (userData) {
      // if (displayVideoModal?.isDisplayModal) {
      //   setShowVideoModal(true);
      // } else {
      //   setShowVideoModal(false);
      // }

      //show onboarding modal
      if (
        userData?.is_profile_completed !== undefined &&
        !userData?.is_profile_completed
      ) {
        setShowOnboardingModal(true);
        history.push(urlConstantsEnum.ONBOARDING_STEP_1);
        document.body.classList.add('hide-chat');
      } else {
        if (userData?.is_profile_completed && !userData?.agreed_terms_of_service) {
          setOnboardingStep(onBoardingStepsEnum.TERMS_OF_SERVICE);
          setShowOnboardingModal(true);
        } else {
          setShowOnboardingModal(false);
          guid ? redirectToDashboard(tabIndex, guid) : redirectToDashboard();
        }
        document.body.classList.remove('hide-chat');
      }

      //log new user signn up evennt
      if (userData?.is_first_login === true) {
        mixpanel.track('New user signed up', {
          user_guid: userData.guid,
        });
        mixpanel.track('User first logged in', {
          user_guid: userData.guid,
        });
      }
    }
  }, [userData]);

  const onClickOfCloseButton = () => {
    setShowOnboardingModal(false);
    redirectToDashboard();
    document.body.classList.remove('hide-chat');
  };

  const OnClickOfStripeErrorModalClose = () => {
    setShowStripeErrorModal(false);
    dispatch(resetSubscriptionError());
  };

  const getFirstPageOrdersBySpecialty = (specialty) => {
    guid = '';
    redirectToDashboard();
    setSpecialtyTag(specialty);
    setOrderIndex(null);
    dispatch(resetOrderIndexData({}));
    dispatch(
      setSpecialtyText({
        specialtyText: specialty,
      }),
    );
    getOrderData(statusHeaderEnum.ALL, 'newest-first', orderScope, 1, specialty);
  };

  const getFirstPageOrdersByStatus = (status) => {
    guid = '';
    redirectToDashboard();
    var orderStatus1 = '';
    switch (status) {
      case 'Pending':
        orderStatus1 = statusHeaderEnum.PENDING;
        break;
      case 'In Progress':
        orderStatus1 = statusHeaderEnum.INPROGRESS;
        break;
      default:
      case 'Completed':
        orderStatus1 = statusHeaderEnum.COMPLETED;
        break;
      case 'Triage':
        orderStatus1 = statusHeaderEnum.TRIAGE;
        break;
    }
    setOrderIndex(null);
    setOrderFilter(orderStatus1);
    dispatch(resetOrderIndexData({}));
    getOrderData(orderStatus1, 'newest-first', orderScope, 1, specialtyTag);
  };

  const getFirstPageOrdersByDataSet = (dataset) => {
    guid = '';
    redirectToDashboard();
    setOrderIndex(null);
    setDataSourceTag(dataset);
    dispatch(resetOrderIndexData({}));
    getOrderData(
      statusHeaderEnum.ALL,
      'newest-first',
      orderScope,
      1,
      specialtyTag,
      dataset,
    );
  };

  const getFirstPageOrdersByMotivation = (motivation) => {
    guid = '';
    redirectToDashboard();
    setOrderIndex(null);
    setMotivationTag(motivation);
    dispatch(resetOrderIndexData({}));
    getOrderData(
      statusHeaderEnum.ALL,
      'newest-first',
      orderScope,
      1,
      specialtyTag,
      dataSourceTag,
      motivation,
    );
  };

  const getFirstPageOrders = (
    status,
    orderBy,
    specialty,
    dataSourceValue,
    providerMotivationValue,
    isCloseOpenedOrder,
  ) => {
    if (specialty || dataSourceValue || providerMotivationValue || isCloseOpenedOrder) {
      setOrderIndex(null);
      redirectToDashboard();
      dispatch(resetOrderIndexData({}));
    }
    setSpecialtyTag(specialty);
    setDataSourceTag(dataSourceValue);
    setMotivationTag(providerMotivationValue);
    dispatch(
      setSpecialtyText({
        specialtyText: specialty,
      }),
    );
    setOrderFilter(status);
    getOrderData(
      status,
      orderBy,
      orderScope,
      1,
      specialty,
      dataSourceValue,
      providerMotivationValue,
    );
  };

  const setTitleValue = () => {
    const classExists =
      document.getElementsByClassName('accordian-header header-is-expanded').length > 0;

    if (!classExists) {
      setShowTitle(true);
    } else {
      setShowTitle(false);
    }
  };

  const wordsTruncate = (words, length, i) => {
    if (length >= words.length) {
      return words;
    } else {
      var newTitle = words.substring(0, length);
      if (showTitle && i === clickedTitleKey) {
        return (
          <div
            className="titleDiv"
            onClick={() => {
              setTitleValue();
              setClickedTitleKey(i);
            }}
          >
            {words + ' ...'}
            <button
              className="seeMore"
              onClick={(event) => {
                event?.stopPropagation();
                setClickedTitleKey(i);
                setShowTitle(false);
              }}
              id={'hide-button-link'}
            >
              hide
            </button>
          </div>
        );
      } else {
        return (
          <div
            className="titleDiv"
            onClick={() => {
              setTitleValue();
              setClickedTitleKey(i);
            }}
          >
            {newTitle + ' ...'}
            <button
              className="seeMore"
              onClick={(event) => {
                event?.stopPropagation();
                setClickedTitleKey(i);
                setShowTitle(true);
              }}
              id={'see-ore-button-link'}
            >
              see more
            </button>
          </div>
        );
      }
    }
  };
  const getOrderData = async (
    status = statusHeaderEnum.ALL,
    orderBy = 'newest-first',
    scope = orderScopeEnum.PERSONAL,
    pageNumber = 1,
    specialty,
    dataSource = '',
    motivation = '',
  ) => {
    var scope1 = scope == 'email-verification-pending' ? orderScopeEnum.PERSONAL : scope;
    // improvment trigger twice
    // getFiltersListData();
    setOrderPageNumber(pageNumber);
    setOrderBy(orderBy);
    const token = await getIdTokenClaims();
    var params = {
      size: ORDER_PAGINATION_SIZE,
      sort: orderBy,
      scope: scope1,
      page: pageNumber,
      specialty: specialty,
      datasource: dataSource,
      motivation: motivation,
      sort_date_type: scope === 'personal' ? 'order_date' : 'order_completed_date',
    };
    if (status !== 'all' && status !== '') {
      params.status = status;
    }
    if (token) {
      if (verificationStatus === 'Verified') {
        // improvment trigger twice
        dispatch(
          getOrderDetails({
            params,
            orderIndexData: orderIndexData ? orderIndexData : '',
            token: token.__raw,
          }),
        );
      }
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    const getOrderStatusesCount = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getOrderStatusCount({
          token: getRawToken(token),
        }),
      );
    };
    if (verificationStatus === 'Verified') {
      getOrderStatusesCount();
    }
  }, [dispatch, getIdTokenClaims]);

  const history = useHistory();
  const handleClick = () => {
    if (singleOrderData) {
      dispatch(resetSingleOrderData({}));
    }
    dispatch(resetOrderIndexData({}));
    if (currentOrderMotivation) dispatch(setMotivation(undefined));
    history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE);
  };

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return <ErrorBoundaryModal error={error} resetErrorBoundary={resetErrorBoundary} />;
  };

  if (userProfileError) {
    throw new Error(userProfileError);
  }

  const actionCallback = (panels, state) => {
    dispatch(resetDownloadLink());
    dispatch(resetOrderIndexData({}));
    if (state.active[0] !== undefined) {
      let orderIndex = state.active[0];
      setOrderIndex(orderIndex);
      setIsOrderOpednedFromPortal(true);
      setShowTitle(true);
      guid = orderData[orderIndex].guid;
      redirectToDashboard(tabIndex, orderData[orderIndex].guid);
    } else {
      setIsOrderOpednedFromPortal(false);
      setOrderIndex(null);
      setShowTitle(false);
      guid = '';
      redirectToDashboard();
    }
  };

  const setUrlWithGuid = () => {
    if (orderIndex !== null) {
      redirectToDashboard(tabIndex, orderData[orderIndex]?.guid);
      mixpanel.track('Screen path changed event', {
        screen_path: window.location.pathname,
      });
    }
    if (showVideoModal) {
      redirectToDashboard();
    }
    if (userData?.is_profile_completed !== undefined && !userData?.is_profile_completed) {
      history.push(urlConstantsEnum.ONBOARDING_STEP_1);
    }
  };
  useEffect(() => {
    setUrlWithGuid();
  }, [orderIndex, tabIndex]);

  useEffect(() => {
    if (!isAuthorised) {
      history.push(urlConstantsEnum.EMAIL_VERIFICATION_PENDING);
      mixpanel.track('Screen path changed event', {
        screen_path: window.location.pathname,
      });
    } else {
      setUrlWithGuid();
    }
  }, [isAuthorised]);

  useEffect(() => {
    setScrollElement(document.querySelector('.single-prognostorm'));
  });

  useEffect(() => {
    if (scrollElement !== null) {
      scrollToOrder();
    }
  }, [scrollElement, orderIndex]);

  const scrollToOrder = () => {
    if (orderIndex !== null) {
      var scrollElement = document.querySelector(
        '.single-prognostorm:nth-child(' + orderIndex + ') .details',
      );
      if (scrollElement) {
        const { innerHeight: height } = window;
        const eleTop = scrollElement.getBoundingClientRect().top;
        if (innerHeight - eleTop < 340 || eleTop < -100) {
          window.scroll({
            top: document.querySelector(
              '.single-prognostorm:nth-child(' + orderIndex + ')',
            ).offsetTop,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  const displayOrder = () => {
    return isOrderLoading && orderPageNumber === 1 ? (
      <div className="position-relative">
        <AuthLoader fullScreen={false} />
      </div>
    ) : (
      <>
        {!!getOrderError ? (
          <>
            {getOrderError === orderFormErrorStr.commonError ? (
              <div className="text-center color-error error-text">
                Something went wrong. Please{' '}
                <a href={`mailto:${labels.supportEmail}`} className="color-error">
                  contact support
                </a>{' '}
                for help!
              </div>
            ) : (
              <div className="text-center color-error error-text">{getOrderError}</div>
            )}
          </>
        ) : (
          <div>{showOrders()}</div>
        )}
      </>
    );
  };

  const showOrders = () => {
    if (waitingForOrders && orderPageNumber === 1) {
      <div className="position-relative">
        <AuthLoader fullScreen={false} />
      </div>;
    } else {
      if (
        orderData !== undefined &&
        orderData?.length !== 0 &&
        Array.isArray(orderData)
      ) {
        return (
          <InfiniteScroll
            dataLength={orderData?.length}
            next={fetchMoreData}
            hasMore={hasMoreOrders}
            loader={
              <div className="position-relative">
                <AuthLoader fullScreen={false} />
              </div>
            }
          >
            <AccordionWithHeader
              className="accordian-container mt-4 mt-md-0"
              actionCallback={actionCallback}
              active={orderIndex !== null ? [orderIndex] : []}
            >
              {orderData?.map((order, i) => {
                var bgColor = '';
                if (questionData) {
                  for (let i = 0; i < questionData?.length; i++) {
                    if (
                      questionData &&
                      questionData[i] &&
                      questionData[i].name === order?.question_type
                    ) {
                      bgColor = questionData[i].style.backgroundColor;
                    }
                  }
                }
                return (
                  <AccordionNode
                    key={i}
                    className="single-prognostorm pt-4 pb-1 position-relative text-bold"
                  >
                    <AccordionHeader className={`accordian-header`}>
                      <div
                        className="title mt-1 mb-3 pr-6 pr-md-6"
                        id={`order-title-${i}`}
                      >
                        {order.picot_status === true
                          ? order.clinical_questions
                            ? wordsTruncate(
                                order.clinical_questions,
                                QUESTION_TEXT_SIZE,
                                i,
                              )
                            : wordsTruncate(order.question_title, QUESTION_TEXT_SIZE, i)
                          : wordsTruncate(
                              order.clinical_questions,
                              QUESTION_TEXT_SIZE,
                              i,
                            )}
                      </div>

                      <div
                        className="position-absolute down-arrow"
                        onClick={() => {
                          setClickedTitleKey(i);
                          setTitleValue();
                        }}
                        id={`order-arrow-${i}`}
                      >
                        <ButtonLink
                          buttonAction="Order expand button clicked"
                          id={`order-arrow-${i}`}
                        >
                          <HiOutlineChevronDown />
                        </ButtonLink>
                      </div>
                    </AccordionHeader>
                    <PrognostorgramOrderDetails
                      uuid={getCaseOrSearchID(order)}
                      email={order.email_address ? order.email_address : ''}
                      date={
                        tabIndex === 0
                          ? dateFormat(order.order_date)
                          : dateFormat(order.order_completed_date)
                      }
                      tag={order.question_type}
                      uniqueKey={`order-${i}-${order.modified_date}`}
                      color={bgColor}
                      questionStatus={order.status}
                      tabIndex={tabIndex}
                      filterBySpecialty={() =>
                        getFirstPageOrdersBySpecialty(order.provider_specialty)
                      }
                      filterByStatus={() => getFirstPageOrdersByStatus(order.status)}
                      filterByDataSet={() =>
                        getFirstPageOrdersByDataSet(order.data_source)
                      }
                      filterByMotivation={getFirstPageOrdersByMotivation}
                      specialtyTag={order.provider_specialty}
                      dataSource={order.data_source}
                      motivations={order.motivations}
                      dataSourceDetails={
                        order?.data_source_object?.name
                          ? order.data_source_object
                          : undefined
                      }
                      order={order}
                    />
                    <AccordionPanel className="accordian-panel">
                      <PicotOptions
                        orderGuid={order.guid}
                        population={order.population}
                        intervention={order.intervention}
                        control={order.control}
                        outcome={order.outcome}
                        timeframe={order.timeframe}
                        onModify={() => modifyOrder(order, i)}
                        onEdit={() => editOrder(order, i)}
                        questionStatus={order.status}
                        onCancelOrder={() => cancelOrder(order.guid)}
                        tabIndex={tabIndex}
                        pdfFile={pdfFile}
                        conclusion={order.conclusion}
                        summary={order.summary}
                        order={order}
                      />
                    </AccordionPanel>
                  </AccordionNode>
                );
              })}
            </AccordionWithHeader>
          </InfiniteScroll>
        );
      } else if (orderFilter !== statusHeaderEnum.ALL) {
        return (
          <NoOrder
            imgUrl={OrderEmptyIcon}
            headerText="No results found"
            bodyText={bodyTextTemplate}
          />
        );
      } else {
        return showEmptyScope();
      }
    }
  };

  const showEmptyScope = () => {
    switch (tabIndex) {
      case 0:
      default:
        return <PersonalNoOrder />;
      case 1:
        return (
          <NoOrder
            imgUrl={OrderEmptyIcon}
            headerText="No results found"
            bodyText={bodyTextTemplate}
          />
        );
    }
  };

  const cancelOrder = async (orderGuid) => {
    redirectToDashboard();
    const token = await getIdTokenClaims();
    dispatch(
      deleteOrder({
        token: getRawToken(token),
        orderGuid: orderGuid,
        deleteOrderCallback: showModalOnDelete,
      }),
    );
  };

  const showModalOnDelete = () => {
    setOrderIndex(null);
    dispatch(resetOrderIndexData({}));
    getOrderData(statusHeaderEnum.PENDING, ordersBy, orderScope, 1, specialtyTag);
    setShowModal(true);
  };

  const OnClickOfClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  // improvment => trigger twice => happened only on development mode
  useEffect(() => {
    getFiltersListData();
  }, [orderScope]);

  useEffect(() => {
    return () => {
      if (isNotificationModalOpen) {
        dispatch(handleOrderDetailModal(false));
      }
    };
  }, []);

  const getFiltersListData = async () => {
    const token = await getIdTokenClaims();
    if (userData && userData?.is_profile_completed) {
      var scope1 =
        orderScope == 'email-verification-pending' ? orderScopeEnum.PERSONAL : orderScope;
      dispatch(
        getSpecialtyList({
          token: getRawToken(token),
          scope: scope1 ? scope1 : orderScopeEnum.PERSONAL,
        }),
      );
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  const onClickOfVideoModalClose = () => {
    setShowVideoModal(false);
    dispatch(setVideoModalDisplay({}));
    updatedTraningVideoDisplaySetting();
  };

  const updatedTraningVideoDisplaySetting = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      updateProfileInfo({
        params: {
          is_training_completed: true,
        },
        userGuid: userData.guid,
        token: getRawToken(token),
      }),
    );
  };

  const editOrder = (order, i) => {
    const { documents } = orderData[i];
    setOrderIndex(i);
    dispatch(
      setOrderIndexData({
        index: i,
        orders: orderData,
        pageNumber: pageNumber,
        orderGuid: orderData[i].guid,
        isOpened: true,
      }),
    );
    // if(documents && documents.length > 0){
    //   const docArr = documents.map(doc => ({...doc, is_uploaded: false}));
    //   dispatch(setUploadedFile(docArr));
    // }
    modifyAndOrderAgain(order, true);
  };
  const modifyOrder = (order, i) => {
    const { documents } = orderData[i];
    setOrderIndex(i);
    dispatch(
      setOrderIndexData({
        index: i,
        orders: orderData,
        pageNumber: pageNumber,
        orderGuid: orderData[i].guid,
        isOpened: true,
      }),
    );
    // if(documents && documents.length > 0){
    //   const docArr = documents.map(doc => ({...doc, is_uploaded: false}));
    //   dispatch(setUploadedFile(docArr));
    // }
    modifyAndOrderAgain(order, false);
  };
  const modifyAndOrderAgain = (order, edit) => {
    // dispatch(
    //   setOrderFormData({
    //     firstName: first_name,
    //     lastName: last_name,
    //     email: email,
    //     motivations: order.motivations,
    //     physicianInstitution: institute?.name,
    //     clinicalQuestion: order.clinical_questions
    //       ? order.clinical_questions
    //       : order.question_title,
    //     previous_order_guid: order.guid,
    //     question_type: order.question_type,
    //     questionTitle: order.question_title
    //       ? order.question_title
    //       : order.clinical_questions,
    //     population: order.population,
    //     intervention: order.intervention,
    //     control: order.control,
    //     outcome: order.outcome,
    //     timeframe: order.timeframe,
    //     picotSwitch: order.picot_status,
    //     orderStatus: order.status,
    //     isEditOrder: edit,
    //     institute_name: institute?.name,
    //     institute_guid: '',
    //     data_source: order.data_source,
    //     sharing_type: order.sharing_type,
    //     urgencyType: order.is_urgent,
    //     documentFileName: order.document,
    //     phiDisclaimer: order.phi_disclaimer,
    //     documentKeyPath: order.key_path,
    //     requester: order.requester,
    //     patient: order.patient,
    //     data_source_object: edit ? order.data_source_object : ''
    //   }),
    // );
    history.push(
      `${urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE}/${
        edit === true ? 'edit' : 'reorder'
      }/${order.guid}`,
    );
  };

  const bodyTextTemplate = () => {
    return (
      <div className="no-order-text my-3">
        Please try adjusting your search{' '}
        {orderFilter !== statusHeaderEnum.ALL && tabIndex === 0 ? 'or filters' : ''} to
        find what you’re looking for, or order a new prognostogram{' '}
        <ButtonSecondary
          cssClass="link"
          onClick={handleClick}
          label="here"
          buttonAction="Order a prognostogram button clicked"
          id={'order-PG-button'}
        />
        .
      </div>
    );
  };

  useEffect(() => {
    dispatch(clearProfileError());
    dispatch(resetUploadDocumentData());
    dispatch(resetDownloadLink());
    dispatch(resetChartrwdErrors());
    dispatch(clearUploadDocumentsList());
  }, [dispatch]);

  useEffect(() => {
    if (notificationGuid) {
      async function handleReadNotification() {
        const token = await getIdTokenClaims();
        dispatch(
          readNotification({
            token: getRawToken(token),
            userGuid: userData.guid,
            notificationGuid: notificationGuid,
            isExternalPage: true,
          }),
        );
      }
      handleReadNotification();
    }
  }, [notificationGuid]);

  const getOrdersByType = (index) => {
    dispatch(resetOrderIndexData({}));
    setSpecialtyTag('');
    setDataSourceTag('');
    setMotivationTag('');
    setOrderIndex(null);
    setTabIndex(index);
    let scope = '';
    switch (index) {
      case 0:
      default:
        scope = orderScopeEnum.PERSONAL;
        setOrderScope(scope);
        break;
      case 1:
        scope = orderScopeEnum.LIBRARY;
        setOrderScope(scope);
        break;
    }
    redirectToDashboard(index);
    mixpanel.track('Tab clicked', {
      selected_tab_value: scope,
    });
    dispatch(setOrderScopeData({ scopeTabIndex: index, orderScope: scope }));
    getOrderData(
      index === 1 ? statusHeaderEnum.ALL : orderFilter,
      ordersBy,
      scope,
      1,
      specialtyTag,
    );
    if (currentOrderMotivation) {
      dispatch(setMotivation(undefined));
    }
    dispatch(stopBackgroundSync());
  };

  const fetchMoreData = () => {
    if (hasMoreOrders) {
      getOrderData(orderFilter, ordersBy, orderScope, pageNumber, specialtyTag);
    }
  };

  const resendEmailVerification = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      sendEmailVerification({
        params: { email: email },
        token: getRawToken(token),
      }),
    );
  };

  const returnBgColor = (question_type) => {
    var bgColor = '';
    if (questionData) {
      for (let i = 0; i < questionData?.length; i++) {
        if (questionData && questionData[i] && questionData[i].name === question_type) {
          bgColor = questionData[i].style.backgroundColor;
        }
      }
    }
    return bgColor;
  };

  return (
    <div className="home-container">
      {!!userProfileError ? (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            logOutUser();
          }}
        ></ErrorBoundary>
      ) : (
        <div>
          {viewAsSession?.impersonate_header &&
          viewAsSession?.user?.email &&
          userData?.last_login == null ? (
            <ViewAsErrorTemplate />
          ) : (
            <>
              {isAuthorisedChecked ? (
                <>
                  {isAuthorised ? (
                    <>
                      {isLoading ? (
                        <div className="position-relative">
                          <AuthLoader fullScreen={false} />
                        </div>
                      ) : (
                        <Onboarding
                          show={showOnboardingModal}
                          onboardingStep={onboardingStep}
                          closeButton={onClickOfCloseButton}
                        />
                      )}
                      <CustomModal
                        isOpen={showStripeErrorModal}
                        handleClose={OnClickOfStripeErrorModalClose}
                        cssClass="white-overlay"
                        modalname="Order cancel modal"
                        overlayCssClass={'cancel-modal-overlay'}
                      >
                        <div
                          ref={ref}
                          className={`email-modal p-3 delete-modal text-center color-white d-flex flex-justify-between ${
                            !!deleteOrderError ? 'bg-error' : 'bg-delete-success'
                          }`}
                        >
                          <div className="small">
                            {subscriptionInitiationError
                              ? subscriptionInitiationError
                              : 'Something went wrong. Please contact support for help!'}
                          </div>
                          <ButtonCustom
                            cssClass="close-btn color-white"
                            onClick={() => OnClickOfStripeErrorModalClose()}
                            buttonAction={'Modal closed button clicked'}
                            id={'btn-modal-closed-button'}
                          >
                            <VscChromeClose />
                          </ButtonCustom>
                        </div>
                      </CustomModal>
                      <CustomModal
                        isOpen={showModal && !isOrderLoading}
                        handleClose={OnClickOfClose}
                        cssClass="white-overlay"
                        modalname="Order cancel modal"
                        overlayCssClass={'cancel-modal-overlay'}
                      >
                        <div
                          ref={ref}
                          className={`email-modal px-5 py-3 delete-modal text-center color-white d-flex flex-justify-between ${
                            !!deleteOrderError ? 'bg-error' : 'bg-delete-success'
                          }`}
                        >
                          {!!deleteOrderError ? (
                            <div className="small">{deleteOrderError}</div>
                          ) : (
                            <div className="small">
                              Prognostogram was cancelled successfully.
                            </div>
                          )}
                          <ButtonCustom
                            cssClass="close-btn color-white"
                            onClick={() => OnClickOfClose()}
                            buttonAction={'Modal closed button clicked'}
                            id={'btn-modal-closed-button'}
                          >
                            <VscChromeClose />
                          </ButtonCustom>
                        </div>
                      </CustomModal>
                      {/* <CustomModal
                    isOpen={isDisplayOrder || isNotificationModalOpen}
                    handleClose={() => OnClickOfOrderClose(singleOrderData?.is_owner)}
                    cssClass="white-overlay"
                    modalname="Order modal"
                    overlayCssClass={'order-modal-overlay'}
                  >
                    <div ref={ref} className={`email-modal p-3 order-modal`}>
                      {isgettingOrderDetails ? (
                        <div className="position-relative">
                          <AuthLoader fullScreen={false} />
                        </div>
                      ) : (
                        <>
                          {singleOrderData &&
                          Object.keys(singleOrderData).length !== 0 ? (
                            <div className="single-prognostorm">
                              <div
                                className="title mt-1 mb-3 pr-6 pr-md-6"
                                id="order-title-0"
                              >
                                {singleOrderData.picot_status === true
                                  ? singleOrderData.question_title
                                    ? singleOrderData.question_title
                                    : singleOrderData.clinical_questions
                                  : singleOrderData.clinical_questions}
                              </div>
                              <PrognostorgramOrderDetails
                                uuid={
                                  singleOrderData.case_uuid
                                    ? singleOrderData.case_uuid
                                    : ''
                                }
                                email={
                                  singleOrderData.email_address
                                    ? singleOrderData.email_address
                                    : ''
                                }
                                date={
                                  tabIndex === 0
                                    ? dateFormat(singleOrderData.order_date)
                                    : dateFormat(singleOrderData.order_completed_date)
                                }
                                tag={singleOrderData.question_type}
                                uniqueKey={`order--${singleOrderData.modified_date}`}
                                color={returnBgColor(singleOrderData.question_type)}
                                questionStatus={singleOrderData.status}
                                tabIndex={tabIndex}
                                filterBySpecialty={() =>
                                  getFirstPageOrdersBySpecialty(
                                    singleOrderData.provider_specialty,
                                  )
                                }
                                filterByStatus={() =>
                                  getFirstPageOrdersByStatus(singleOrderData.status)
                                }
                                filterByDataSet={() =>
                                  getFirstPageOrdersByDataSet(singleOrderData.data_source)
                                }
                                filterByMotivation={getFirstPageOrdersByMotivation}
                                specialtyTag={singleOrderData.provider_specialty}
                                dataSource={singleOrderData.data_source}
                                motivations={singleOrderData.motivations}
                                dataSourceDetails={singleOrderData.data_source_object.name ? singleOrderData.data_source_object : undefined }
                              />
                              <PicotOptions
                                orderGuid={singleOrderData.guid}
                                population={singleOrderData.population}
                                intervention={singleOrderData.intervention}
                                control={singleOrderData.control}
                                outcome={singleOrderData.outcome}
                                timeframe={singleOrderData.timeframe}
                                onModify={() => modifyOrder(singleOrderData, 0)}
                                onEdit={() => editOrder(singleOrderData, 0)}
                                questionStatus={singleOrderData.status}
                                onCancelOrder={() => cancelOrder(singleOrderData.guid)}
                                tabIndex={tabIndex}
                                pdfFile={pdfFile}
                                conclusion={singleOrderData.conclusion}
                                summary={singleOrderData.summary}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                          {getOrderDetailError && (
                            <div className="d-flex error-container my-4">
                              <div className="mr-3 flex-self-center">
                                <RiErrorWarningFill className="d-block" />
                              </div>
                              <div className="flex-self-center">
                                Oops! It looks like you do not have access to that
                                Prognostogram. If you think this is an error, please reach
                                out to our team at{' '}
                                <a href="mailto:support@atroposhealth.com">
                                  Support@atroposhealth.com
                                </a>
                              </div>
                            </div>
                          )}

                          <ButtonCustom
                            cssClass="close-btn color-white"
                            onClick={() => OnClickOfOrderClose(singleOrderData?.is_owner)}
                            buttonAction={'Modal closed button clicked'}
                            id={'btn-modal-closed-button'}
                          >
                            <VscChromeClose />
                          </ButtonCustom>
                        </>
                      )}
                    </div>
                  </CustomModal> */}
                      <OrderdetailModal
                        modalRef={ref}
                        isOpen={isDisplayOrder}
                        handleClose={() => OnClickOfOrderClose(singleOrderData?.is_owner)}
                        isLoading={isgettingOrderDetails}
                        data={singleOrderData}
                        error={getOrderDetailError}
                        tabIndex={tabIndex}
                        questionData={questionData}
                        pdfFile={pdfFile}
                        filterBySpecialty={() =>
                          getFirstPageOrdersBySpecialty(
                            singleOrderData.provider_specialty,
                          )
                        }
                        filterByStatus={() =>
                          getFirstPageOrdersByStatus(singleOrderData.status)
                        }
                        filterByDataSet={() =>
                          getFirstPageOrdersByDataSet(singleOrderData.data_source)
                        }
                        filterByMotivation={getFirstPageOrdersByMotivation}
                        onModify={() => modifyOrder(singleOrderData, 0)}
                        onEdit={() => editOrder(singleOrderData, 0)}
                        onCancelOrder={() => cancelOrder(singleOrderData.guid)}
                        OnClickOfOrderClose={() =>
                          OnClickOfOrderClose(singleOrderData?.is_owner)
                        }
                      />
                      <TutorialVideoModal
                        isOpen={showVideoModal}
                        handleClose={onClickOfVideoModalClose}
                        subscriptionType={displayVideoModal?.subscriptionType}
                      />
                      <div className="my-md-7">
                        <Tabs
                          selectedTabClassName="active-tab gradient-btn "
                          className="custom-tabs"
                          onSelect={(index) => getOrdersByType(index)}
                          defaultIndex={tabIndex}
                        >
                          <div className="d-flex flex-md-justify-between mb-4 flex-md-row flex-column-reverse">
                            <TabList className="d-flex custom-tablist">
                              <Tab id={'tab-personal'}>Personal</Tab>
                              <Tab id={'tab-library'}>Library</Tab>
                            </TabList>
                            <ButtonSecondary
                              label="Order a Prognostogram"
                              cssClass="order-btn my-4 my-md-0"
                              onClick={handleClick}
                              buttonAction="Order a prognostogram button clicked"
                              id={'btn-order-prognostogram-button'}
                            />
                          </div>

                          <SearchForm />
                          <DashboardHeader
                            setNewStatusFilter={getFirstPageOrders}
                            orderStatusCountData={orderStatusCountData}
                            tabIndex={tabIndex}
                            orderListCount={orderListCount ? orderListCount : 0}
                            hideLibraryMessage={waitingForOrders && orderPageNumber === 1}
                            status={orderFilter}
                            specialty={specialtyTag}
                            dataSource={dataSourceTag}
                            motivation={motivationTag}
                          />
                          <TabPanel>{displayOrder()}</TabPanel>
                          <TabPanel>{displayOrder()}</TabPanel>
                        </Tabs>
                      </div>
                    </>
                  ) : (
                    <UserUnverifiedTemplate
                      resendEmailVerification={resendEmailVerification}
                      verification_status={verificationStatus}
                      sendEmailSuccessMessage={sendEmailSuccessMessage}
                      sendEmailVerificationError={sendEmailVerificationError}
                    />
                  )}
                </>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      )}
      <CancelOrderModal cancelOrder={cancelOrder} />
    </div>
  );
};
export default Home;
