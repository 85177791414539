import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { Frame } from 'components/Frame';
import { labels } from 'constants/labels';
import { Strings } from 'constants/Strings';
import { Button } from 'components/button';
import { ReactComponent as CheckGray } from 'assets/images/CheckGray.svg';
import { ReactComponent as Check } from 'assets/images/Check.svg';
import { Dropdown } from 'components/dropdown';

function MotivationSelect() {
  const { motivations, singleOrderData } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const { ordersPageData, dispatchOrderPageAction } = useOrdersPagedata();
  const {
    chatRWD: { selectedProviderMotivation, errors, hasMotivationSelectChanged },
  } = ordersPageData;

  const { motivations: ordersMotivations, patient } = singleOrderData || {};

  useEffect(() => {
    if (ordersMotivations && ordersMotivations.length) {
      const presentMotivations = ordersMotivations.find((motivation) => motivation.value);
      if (presentMotivations) {
        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'selectedProviderMotivation',
            value: presentMotivations?.category,
          },
        });
      }
    }
  }, [ordersMotivations, patient]);

  function renderMotivationOption() {
    return motivations && motivations.length > 0
      ? motivations
          ?.filter(
            (motivation) => motivation.category.toLowerCase() !== labels.patientCare,
          )
          .map((motivation) => ({
            label: motivation.category,
            value: motivation.category,
          }))
      : [];
  }

  const handleClickonOption = (option) => {
    if (!option?.value) {
      dispatchOrderPageAction({
        type: 'order/setChatRWDErrorByKey',
        payload: {
          key: 'motivation',
          error: Strings.motivationError,
        },
      });
    }

    if (errors?.motivation && option?.value) {
      dispatchOrderPageAction({
        type: 'order/setChatRWDErrorByKey',
        payload: {
          key: 'motivation',
          error: '',
        },
      });
    }

    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'selectedProviderMotivation',
        value: option?.value,
      },
    });
  };

  function handleButtonClick() {
    if (!selectedProviderMotivation) {
      dispatchOrderPageAction({
        type: 'order/setChatRWDErrorByKey',
        payload: {
          key: 'motivation',
          error: Strings.motivationError,
        },
      });
      return;
    }
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'hasMotivationSelectChanged',
        value: true,
      },
    });
  }

  return (
    <>
      <Frame>
        <Frame.Aside></Frame.Aside>
        <Frame.Body>
          <div className="w-100">
            <div className="txt size-16 gray-20 mb-3 lh-28 intro-text">
              Select your motivation:
            </div>

            <Dropdown
              placeholder="Select Answer"
              options={renderMotivationOption()}
              onChange={handleClickonOption}
              value={selectedProviderMotivation}
              showClear
              invalid={errors?.motivation ? true : false}
              dropdownname="chatRWD motivation"
            />
            {errors.motivation && (
              <div className="color-error error-text">{errors.motivation}</div>
            )}
          </div>
        </Frame.Body>
      </Frame>
      <Frame.Footer>
        <div className="flex-wrap flex-justify-end d-flex gap-7">
          {!hasMotivationSelectChanged ? (
            <Button
              outlined
              label={labels.submitMotivation}
              icon={<CheckGray />}
              onClick={handleButtonClick}
            />
          ) : (
            <Button label={labels.submitMotivation} icon={<Check />} cursor='not-allowed' />
          )}
        </div>
      </Frame.Footer>
    </>
  );
}

export default MotivationSelect;
