import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PicotDataDisplay from './PicotDataDisplay';
import StudyDetailsSummary from './StudyDetailsSummary';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { Frame } from 'components/Frame';
import { Button } from 'components/button';
import { ReactComponent as Check } from 'assets/images/Check.svg';
import StudyDetailsEditForm from './StudyDetailsEditForm';
import Suggestions from '../suggestions/Suggestions';

const defaultButtons = {
  TRY_AGAIN: 'Try Again',
  START_OVER: 'Start Over',
};

function StudyDetails({
  studyFormatObject = {},
  customButtons = {},
  dispatch,
  onOptionClick,
  multiSelect = false,
  selectedSuggestionsOptions = undefined,
  optionIndex = null,
}) {
  const { buttons, suggestions, updated_pico, isEdit } = studyFormatObject;
  const [options, setOptions] = useState([]);
  const { PICOGuid } = useSelector(({ orderDetails }) => orderDetails);
  const { showButtonIcons } = useOrdersPagedata();

  function handleButtonClick(option, key) {
    if (
      key === aiButtonsEnum.TRY_AGAIN ||
      key === aiButtonsEnum.START_OVER ||
      key === aiButtonsEnum.MANUALLY_EDIT_QUESTION ||
      key === aiButtonsEnum.SUGGESTIONS_MANUALLY_EDIT ||
      key === aiButtonsEnum.REGENERATE_PICOT ||
      key === aiButtonsEnum.MANUALLY_EDIT_ON_INCORPORATION_ERROR
    ) {
      onOptionClick({ options, key, index: optionIndex });
      return;
    }

    if (multiSelect) {
      switch (key) {
        case aiButtonsEnum.INCORPORATE_CHANGES:
          const selectedSuggestionObj = { ...options };
          dispatch({
            type: 'order/setSuggestionsOptions',
            payload: selectedSuggestionObj,
          });
          onOptionClick({
            options: selectedSuggestionObj,
            key,
            index: optionIndex,
          });
          dispatch({
            type: 'order/setUserAction',
            payload: {
              userAction: key,
              options: { options: options, PICOGuid },
            },
          });
          setOptions([]);
          break;
        case aiButtonsEnum.KEEP_ORIGINAL:
        case aiButtonsEnum.REGENERATE_SUGGESTIONS:
          dispatch({ type: 'order/setSuggestionsOptions', payload: { option } });
          onOptionClick({ option, key, index: optionIndex });

          dispatch({
            type: 'order/setUserAction',
            payload: { userAction: key, options: undefined },
          });
          break;
        default:
          if (!options.includes(option)) {
            setOptions((op) => [...op, option]);
          } else {
            const newOptions = options.filter((op) => op !== option);
            setOptions(newOptions);
          }
          break;
      }
    } else {
      if (!(key === aiButtonsEnum.MANUALLY_EDIT || key === aiButtonsEnum.START_OVER)) {
        dispatch({ type: 'order/setSuggestionsOptions', payload: { option } });

        dispatch({
          type: 'order/setUserAction',
          payload: {
            userAction: key,
            options: { PICOGuid },
          },
        });
      }

      onOptionClick({ option, key, index: optionIndex });
    }
  }

  function handleStudyFormActions(key) {
    onOptionClick({ key, index: optionIndex });
  }

  if (studyFormatObject && 'suggestions' in studyFormatObject) {
    return (
      <>
        <Suggestions
          suggestions={suggestions}
          optionIndex={optionIndex}
          studyFormatObject={studyFormatObject}
          selectedSuggestionsOptions={selectedSuggestionsOptions}
        />
        {/* <Frame>
          <Frame.Aside></Frame.Aside>
          <Frame.Body>
            <StudyDetailsSummary studyFormatObject={studyFormatObject}>
              {suggestions &&
                Array.isArray(suggestions) &&
                suggestions.length > 0 &&
                suggestions.map((suggestion, index) => (
                  <div className="mb-1 detail-item" key={suggestion.id}>
                    <Suggestions
                      suggestion={suggestion}
                      index={index}
                      optionIndex={optionIndex}
                    />
                    <strong className="txt size-16 gray-20 lh-28 text-bold">
                      {index + 1}. {suggestion.name} ({suggestion.field}):{' '}
                    </strong>
                    <span className="txt size-16 gray-20 lh-28">
                      {suggestion.description}
                    </span>
                  </div>
                ))}
            </StudyDetailsSummary>
          </Frame.Body>
        </Frame>

        <Frame.Footer dataTestid={`frame-footer-${optionIndex}`}>
          {!selectedSuggestionsOptions ? (
            <div className="green-border w-100">
              <div className="flex-wrap flex-justify-end d-flex gap-7">
                {buttons && Object.keys(buttons).length > 0
                  ? Object.entries(buttons).map(([key, value], index) => (
                      <React.Fragment key={value}>
                        {suggestions &&
                        Array.isArray(suggestions) &&
                        suggestions.length > 0 ? (
                          <Button
                            outlined={
                              options.includes(`${index + 1}. ${value}`) ? false : true
                            }
                            label={`${index + 1}. ${value}`}
                            dataTestid="suggestion-option"
                            id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                            onClick={() =>
                              handleButtonClick(`${index + 1}. ${value}`, key)
                            }
                          />
                        ) : (
                          <Button
                            outlined={
                              options.includes(`${index + 1}. ${value}`) ? false : true
                            }
                            label={value}
                            id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                            onClick={() => handleButtonClick(value, key)}
                          />
                        )}
                      </React.Fragment>
                    ))
                  : Object.entries(defaultButtons).map(([key, value]) => (
                      <React.Fragment key={value}>
                        <Button
                          outlined
                          icon={showButtonIcons(key)}
                          label={value}
                          id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                          onClick={() => handleButtonClick(value, key)}
                        />
                      </React.Fragment>
                    ))}
              </div>
              <div className="flex-wrap flex-justify-end d-flex gap-7 mt-2">
                {suggestions &&
                  Array.isArray(suggestions) &&
                  suggestions.length > 0 &&
                  customButtons &&
                  buttons &&
                  Object.keys(buttons).length > 0 &&
                  Object.entries(customButtons).map(([key, value], index) => (
                    <React.Fragment key={value}>
                      <Button
                        outlined
                        label={value}
                        id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                        onClick={() => handleButtonClick(value, key)}
                        disabled={key === 'INCORPORATE_CHANGES' && options.length === 0}
                      />
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            <div className="flex-wrap flex-justify-end green-border d-flex gap-7">
              {selectedSuggestionsOptions &&
                Object.entries(selectedSuggestionsOptions).map(([key, value], index) => (
                  <React.Fragment key={value}>
                    <Button
                      label={value}
                      icon={
                        (value === 'Keep Original' ||
                          value === 'Regenerate Suggestions') && <Check />
                      }
                      id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                      onClick={() => {
                        return;
                      }}
                    />
                  </React.Fragment>
                ))}
            </div>
          )}
        </Frame.Footer> */}
      </>
    );
  } else {
    return (
      <>
        {isEdit ? (
          <StudyDetailsEditForm
            studyFormat={studyFormatObject?.study_format}
            data={studyFormatObject}
            onCancel={() =>
              handleStudyFormActions(aiButtonsEnum.CANCEL_CURRENT_FORMAT_EDIT)
            }
            onFormSubmit={() =>
              handleStudyFormActions(aiButtonsEnum.SAVE_CURRENT_FORMAT_EDIT)
            }
          />
        ) : (
          <>
            <Frame dataTestid={`frame-${optionIndex}`}>
              <Frame.Aside></Frame.Aside>
              <Frame.Body>
                <StudyDetailsSummary
                  studyFormatObject={studyFormatObject}
                  onEdit={() => handleButtonClick('Manually Edit', 'MANUALLY_EDIT')}
                  onRefresh={() =>
                    handleButtonClick('Regenerate PICOT', 'REGENERATE_PICOT')
                  }
                  index={optionIndex}
                >
                  {studyFormatObject?.study_format &&
                    Object.entries(studyFormatObject?.study_format).map(
                      ([key, value]) => (
                        <React.Fragment key={key}>
                          <PicotDataDisplay
                            updated_pico={updated_pico}
                            data={{ key, value }}
                          />
                        </React.Fragment>
                      ),
                    )}
                </StudyDetailsSummary>
              </Frame.Body>
            </Frame>
            <Frame.Footer dataTestid={`frame-footer-${optionIndex}`}>
              {!isEdit && !selectedSuggestionsOptions ? (
                <div className="flex-wrap flex-justify-end green-border d-flex gap-10 w-100">
                  {buttons && Object.keys(buttons).length > 0
                    ? Object.entries(buttons).map(
                        ([key, value]) =>
                          ((optionIndex !== 0 &&
                            key !== aiButtonsEnum.REGENERATE_PICOT) ||
                            optionIndex === 0) && (
                            <React.Fragment key={value}>
                              {key === aiButtonsEnum.PICOT_ACCURATE && (
                                <div className="flex-basis-100"> </div>
                              )}
                              <Button
                                outlined
                                label={value}
                                id={`btn-${value
                                  .toLowerCase()
                                  .replace(/\s+/g, '-')}-button`}
                                onClick={() => handleButtonClick(value, key)}
                                icon={showButtonIcons(key)}
                              />
                              {/* <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                                  {showButtonIcons(key)}
                                  <span>{value}</span>
                                </div>
                              </Button> */}
                            </React.Fragment>
                          ),
                      )
                    : Object.entries(defaultButtons).map(([key, value]) => (
                        <React.Fragment key={value}>
                          <Button
                            outlined
                            label={value}
                            id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                            onClick={() => handleButtonClick(value, key)}
                            icon={showButtonIcons(key)}
                          />
                          {/* <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                              {showButtonIcons(key)}
                              <span>{value}</span>
                            </div>
                          </Button> */}
                        </React.Fragment>
                      ))}
                </div>
              ) : (
                !isEdit && (
                  <div className="d-flex flex-wrap flex-justify-end green-border">
                    {selectedSuggestionsOptions &&
                      Object.entries(selectedSuggestionsOptions).map(
                        ([key, value], index) => (
                          <React.Fragment key={value}>
                            <Button
                              label={value}
                              icon={<Check />}
                              id={`btn-${value
                                .toLowerCase()
                                .replace(/\s+/g, '-')}-button`}
                              onClick={() => {
                                return;
                              }}
                              cursor='not-allowed'
                            />
                          </React.Fragment>
                        ),
                      )}
                  </div>
                )
              )}
            </Frame.Footer>
          </>
        )}
      </>
    );
  }
}

export default StudyDetails;
