import React from 'react';
import AiSummaryQuestion from './AiSummaryQuestion';
import Sources from './Sources';
import styled from 'styled-components';
import Answer from './Answer';
import AdditionalEvidence from './AdditionalEvidence';
import SuggestedQuestions from './SuggestedQuestions';

const StyledLine = styled.div`
  border: 0.5px solid var(--border-gray-light);
`;

function AiSummary({ summary, aiSummary }) {
  return (
    <div className="d-flex flex-column gap-20">
      <AiSummaryQuestion question={aiSummary} />
      <Sources sources={summary?.sources} />
      <StyledLine></StyledLine>
      <Answer answer={summary?.answer} />
      <AdditionalEvidence question={aiSummary} />
      <SuggestedQuestions questions={summary?.suggested_follow_up_questions} />
    </div>
  );
}

export default AiSummary;
