import { IconField } from 'primereact/iconfield';
import styled from 'styled-components';

const StyledIconField = styled(IconField)`
  &.p-icon-field-left > .ah-input {
    padding-left: 2rem;
  }
  &.p-icon-field-left.p-float-label > label {
    left: 2rem;
  }
  &.p-icon-field-right > .ah-input {
    padding-right: ${(props) => props.inputfieldrightpadding || '2rem'};
  }
  &.p-icon-field {
    position: relative;
  }
  &.p-icon-field > .p-input-icon {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    right: ${(props) => props.cleariconrightposition || '8rem'};
  }

  &.p-icon-field > .customeInputIcon {
    position: absolute;
    display: flex;
    align-items: center;
    padding: ${(props) => props.cutomIconsPadding || '0 15px'};
    gap: 5px;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid var(--border-gray-neutral);
    background: var(--bg-gray-50);
  }

  &.p-fluid .p-icon-field-left,
  &.p-fluid .p-icon-field-right {
    width: 100%;
  }
  &.p-icon-field-left > .p-input-icon:first-of-type {
    left: 0.5rem;
  }
  &.p-icon-field-right > .customeInputIcon:last-of-type {
    right: 0;
  }

  .customeInputIcon {
    .icon-wrapper {
      padding: 10px;
      transition: background-color 0.3s, transform 0.3s;

      &:hover:not(.disabled) {
        background-color: var(--bg-gray-100);
        border-radius: 6px;
      }

      &.disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }
  svg {
    cursor: pointer;
  }
  &.p-icon-field-right:hover {
    .customeInputIcon {
      border-color: var(--border-secondary-300);
    }
  }
`;

export default StyledIconField;
