import React, { useState } from 'react';
import Source from './Source';
import { labels } from 'constants/labels';
import CustomModal from 'components/CustomModal';
import { ReactComponent as Close } from 'assets/images/Close.svg';

function Sources({ sources }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [open, setOpen] = useState(false);

  function handleClick(index) {
    setActiveIndex(index);
  }

  function handleViewMoreSources() {
    setOpen((open) => !open);
  }

  if (sources && sources?.length > 0) {
    return (
      <>
        <div className="d-flex flex-column gap-10">
          <div className="txt size-16 w-700 lh-22 text-primary-1">{labels.SOURCES}</div>
          <div className="d-flex flex-wrap gap-10">
            {sources.length <= 4 ? (
              sources.map((source, index) => (
                <React.Fragment key={`${source?.document_uri}-${index}`}>
                  <Source
                    source={source}
                    onClick={() => handleClick(index)}
                    activeIndex={activeIndex}
                    index={index}
                    isModal={false}
                  />
                </React.Fragment>
              ))
            ) : (
              <>
                {sources.slice(0, 3).map((source, index) => (
                  <React.Fragment key={`${source?.document_uri}-${index}`}>
                    <Source
                      source={source}
                      onClick={() => handleClick(index)}
                      activeIndex={activeIndex}
                      index={index}
                      isModal={false}
                    />
                  </React.Fragment>
                ))}

                <Source
                  source={{
                    name: labels?.otherSources,
                    title: `View ${sources.length - 3} more sources`,
                    avatars: Array.from({ length: Math.min(sources.length - 3, 5) }, () =>
                      Math.floor(Math.random() * 100),
                    ),
                  }}
                  onClick={handleViewMoreSources}
                  showInfoOverlay={false}
                  isModal={false}
                />
              </>
            )}
          </div>
        </div>

        <CustomModal
          isOpen={open}
          cssClass="white-overlay"
          modalname="View All Sources Modal"
          overlayCssClass="phi-modal-overlay"
          handleClose={() => {}}
        >
          <div
            className="ah-modal-container d-flex flex-column gap-16"
            style={{ backgroundColor: 'var(--bg-gray-50)' }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="txt size-16 w-600">{`${sources.length - 3} sources`}</div>
              <Close className="cursor-pointer" onClick={handleViewMoreSources} />
            </div>
            <div className="d-flex flex-column flex-wrap gap-10">
              {sources.slice(3).map((source, index) => (
                <React.Fragment key={`${source?.document_uri}-${index}`}>
                  <Source
                    source={source}
                    onClick={() => handleClick(index)}
                    activeIndex={activeIndex}
                    index={index}
                    cardwidth="100%"
                    truncatedtextwidth="75"
                    isModal={true}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </CustomModal>
      </>
    );
  }

  return null;
}

export default Sources;
