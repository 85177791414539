import React from 'react';
import { InputIcon } from 'primereact/inputicon';
import StyledIconField from './StyledIconField';
import { ReactComponent as Close } from 'assets/images/Close.svg';
import CustomTooltip from 'components/OverlayTooltip/CustomTooltip';

function IconField({
  iconPosition = 'right',
  showclear = false,
  onIconClick,
  children,
  ...props
}) {
  const { icons = [] } = props || {};

  function handleIconClick(icon) {
    onIconClick(icon);
  }

  return (
    <StyledIconField iconPosition={iconPosition} {...props}>
      {showclear && (
        <InputIcon>
          <Close onClick={() => handleIconClick({ component: null, key: 'clear' })} />
        </InputIcon>
      )}

      <InputIcon className="customeInputIcon">
        {icons.map((icon) => (
          <React.Fragment key={icon.key}>
            {icon?.info ? (
              <div className={`icon-wrapper ${props.disabled && 'disabled'}`}>
                <CustomTooltip
                  position="bottom"
                  content={icon.component}
                  info={icon.info}
                  className="bg-dark"
                  onClick={() => handleIconClick(icon)}
                />
              </div>
            ) : (
              <div
                className={`icon-wrapper ${props.disabled && 'disabled'}`}
                onClick={() => handleIconClick(icon)}
              >
                {icon.component}
              </div>
            )}
          </React.Fragment>
        ))}
      </InputIcon>

      {children}
    </StyledIconField>
  );
}

export default IconField;
