import { Button } from 'components/button';
import { labels } from 'constants/labels';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Flashwhite } from 'assets/images/Flashwhite.svg';
import { useHistory } from 'react-router-dom';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';

const StyledCard = styled.div`
  padding: 20px 15px;
  background: var(--bg-gray-100);
  border-radius: var(--border-radius-8);
`;

function AdditionalEvidence({ question }) {
  const history = useHistory();

  function handleClick() {
    history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE, {
      selectedInteractionType: 3,
      aiSummaryQuestion: question,
    });
  }

  return (
    <StyledCard className="d-flex flex-column gap-20">
      <div className="txt size-20 lh-32 w-700 text-primary-1">
        {labels.generateAdditionalEvidence}
      </div>
      <div className="txt size-16 lh-24 text-black-900">
        Our AI Summary provided an answer with some limitations based on the data
        available in our database and within published research. However, no exact
        evidence exists to answer this question. If you’d like more detail, we suggest you
        create a new study using ChatRWD.
      </div>
      <Button
        label="Answer with ChatRWD"
        icon={<Flashwhite className="txt-white" />}
        style={{
          maxWidth: '250px',
        }}
        onClick={handleClick}
      />
    </StyledCard>
  );
}

export default AdditionalEvidence;
