import styled from 'styled-components';
import { Avatar } from 'primereact/avatar';

const StyledAvatar = styled(Avatar)`
  &.p-avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
  }

  &.p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  &.p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
  }
  &.p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
  &.p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
  }
  &.p-avatar-circle {
    border-radius: 50%;
  }
  .p-avatar-group .p-avatar {
    border: 2px solid #ffffff;
  }
`;

export default StyledAvatar;
