import styled from 'styled-components';
import { Panel } from 'primereact/panel';

export const StyledTogglePanel = styled(Panel)`
  &.ah-toggle-panel {
    width: 100%;
    &:not(.none-padding-bottom) {
      padding-bottom: ${(props) => props.paddingBottom || '15px'};
    }
    &:not(.none-padding-top) {
      padding-top: ${(props) => props.paddingTop || '15px'};
    }

    &.panel-border-bottom {
      border-bottom: ${(props) =>
        props.borderBottom || '0.5px solid var(--border-ocean-green)'};
    }
    .p-panel-header {
      cursor: pointer;
      width: 100%;

      .p-panel-title {
        font-size: ${(props) => props.headerFontSize || '16px'};
        color: ${(props) => props.headercolor || 'var(--color-gray-80)'};
        line-height: 24px;
        letter-spacing: 0.5px;
        width: 100%;
        font-weight: ${(props) => props.headerweight};
      }
      .p-panel-icons {
        display: flex;
      }
    }
  }
`;
