import { order } from 'api/';
import { call, takeLatest, put, fork, cancel } from 'redux-saga/effects';
import {
  GET_SEARCH_QUERY_DETAILS,
  GET_SEARCH_QUERY_DETAILS_START,
  GET_SEARCH_QUERY_DETAILS_COMPLETE,
  GET_SEARCH_QUERY_DETAILS_ERROR,
  GET_SEARCH_DATA,
  GET_SEARCH_DATA_START,
  GET_SEARCH_DATA_COMPLETE,
  GET_SEARCH_DATA_ERROR,
  GET_ORDER_STATUS_COUNT,
  GET_ORDER_STATUS_COUNT_START,
  GET_ORDER_STATUS_COUNT_COMPLETE,
  GET_ORDER_STATUS_COUNT_ERROR,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_START,
  GET_ORDER_DETAILS_COMPLETE,
  GET_ORDER_DETAILS_ERROR,
  GET_QUESTION_TYPES,
  GET_QUESTION_TYPES_START,
  GET_QUESTION_TYPES_COMPLETE,
  GET_QUESTION_TYPES_ERROR,
  ORDER_PROGNOSTOGRAM,
  ORDER_PROGNOSTOGRAM_START,
  ORDER_PROGNOSTOGRAM_COMPLETE,
  ORDER_PROGNOSTOGRAM_ERROR,
  GET_PROGNOSTOGRAM_PDF,
  GET_PROGNOSTOGRAM_PDF_START,
  GET_PROGNOSTOGRAM_PDF_COMPLETE,
  GET_PROGNOSTOGRAM_PDF_ERROR,
  DELETE_ORDER,
  DELETE_ORDER_START,
  DELETE_ORDER_COMPLETE,
  DELETE_ORDER_ERROR,
  MODIFY_PROGNOSTOGRAM_ORDER,
  MODIFY_PROGNOSTOGRAM_ORDER_START,
  MODIFY_PROGNOSTOGRAM_ORDER_COMPLETE,
  MODIFY_PROGNOSTOGRAM_ORDER_ERROR,
  setLoading,
  resetData,
  GET_SPECIALTY_LIST,
  GET_SPECIALTY_LIST_START,
  GET_SPECIALTY_LIST_COMPLETE,
  GET_SPECIALTY_LIST_ERROR,
  GET_ORDER_BY_GUID,
  GET_ORDER_BY_GUID_START,
  GET_ORDER_BY_GUID_COMPLETE,
  GET_ORDER_BY_GUID_ERROR,
  UPLOAD_ORDER_DOCUMENT,
  UPLOAD_ORDER_DOCUMENT_START,
  UPLOAD_ORDER_DOCUMENT_COMPLETE,
  UPLOAD_ORDER_DOCUMENT_ERROR,
  GET_ORDER_DOCUMENT,
  GET_ORDER_DOCUMENT_START,
  GET_ORDER_DOCUMENT_COMPLETE,
  GET_ORDER_DOCUMENT_ERROR,
  GET_MOTIVATIONS_TYPES_START,
  GET_MOTIVATIONS,
  GET_MOTIVATIONS_START,
  GET_MOTIVATIONS_COMPLETE,
  GET_MOTIVATIONS_ERROR,
  GET_DOWNLOAD_LINK,
  GET_DOWNLOAD_LINK_START,
  GET_DOWNLOAD_LINK_COMPLETE,
  GET_DOWNLOAD_LINK_ERROR,
  FEATCH_RESULT_THROUGH_OPEN_AI,
  FEATCH_RESULT_THROUGH_OPEN_AI_START,
  FEATCH_RESULT_THROUGH_OPEN_AI_COMPLETE,
  FEATCH_RESULT_THROUGH_OPEN_AI_ERROR,
  FEATCH_PICO_TO_PHENOTYPE,
  FEATCH_PICO_TO_PHENOTYPE_START,
  FEATCH_PICO_TO_PHENOTYPE_COMPLETE,
  FEATCH_PICO_TO_PHENOTYPE_ERROR,
  SAVE_PICO_OPTIONS,
  SAVE_PICO_OPTIONS_START,
  SAVE_PICO_OPTIONS_COMPLETE,
  SAVE_PICO_OPTIONS_ERROR,
  FEATCH_ORDER_TASK_STATUS,
  FEATCH_ORDER_TASK_STATUS_START,
  FEATCH_ORDER_TASK_STATUS_COMPLETE,
  FEATCH_ORDER_TASK_STATUS_ERROR,
  setSearchOrderLoading,
  SET_UPLOADED_FILE,
  FEATCH_SUGGESTIONS_THROUGH_OPEN_AI,
  FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_START,
  FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE,
  SET_PICO_GUID,
  INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI,
  INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_START,
  INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE,
  GET_ORDER_NOTIFICATION_DETAILS,
  GET_ORDER_NOTIFICATION_DETAILS_START,
  GET_ORDER_NOTIFICATION_DETAILS_COMPLETE,
  GET_ORDER_NOTIFICATION_DETAILS_ERROR,
  FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_ERROR,
  INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_ERROR,
  SYNC_MODIFIED_PHENOTYPE_DATA,
  SYNC_MODIFIED_PHENOTYPE_DATA_START,
  SYNC_MODIFIED_PHENOTYPE_DATA_COMPLETE,
  SYNC_MODIFIED_PHENOTYPE_DATA_ERROR,
  GET_STUDY_DETAILS,
  GET_STUDY_DETAILS_COMPLETE,
  GET_STUDY_DETAILS_ERROR,
} from './actions';
import { ORDER_PAGINATION_SIZE } from 'constants/Constants';
import orderFormErrorStr from '../../../constants/errorStrings';
import { Strings } from 'constants/Strings';
import { constructQuestionFromStudyFormat } from 'utils/questionTextModifier';
import { keyOrder } from 'utils/questionTextModifier';
import * as ordersActions from './actions';

let taskArray = [];
export function* getOrderDeatils({ payload: { token, params, orderIndexData } }) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    yield put({
      type: GET_ORDER_DETAILS_START,
    });
    const { response, error } = yield call(order.getAllOrder, {
      token,
      params,
    });
    if (!!response) {
      //change the response here
      yield put({
        type: GET_ORDER_DETAILS_COMPLETE,
        payload: {
          isIndexSet:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? true
              : false,
          orderData:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? orderIndexData.orders
              : response.data.result,
          pageNumber:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? orderIndexData.pageNumber - 1
              : params.page,
          orderListCount: response.data.count,
          total_pages: response.data.total_pages,
        },
      });
      yield getOrderStatusCounts({ payload: { token } });
      yield put(setLoading({ data: false }));
    } else {
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onGetOrderDetailsError({
          orderData: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onGetOrderDetailsError({
        orderData: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getQuestionTypes({ payload: { token } }) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    yield put({
      type: GET_QUESTION_TYPES_START,
    });
    const { response, error } = yield call(order.getQuestionTypes, {
      token,
    });
    if (!!response) {
      yield put({
        type: GET_QUESTION_TYPES_COMPLETE,
        payload: { questionData: response.data },
      });
      yield put(setLoading({ data: false }));
    } else {
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onGetQuestionError({
          questionData: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onGetQuestionError({
        questionData: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getMotivations({ payload: { token } }) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    yield put({
      type: GET_MOTIVATIONS_START,
    });
    const { response, error } = yield call(order.getMotivations, {
      token,
    });
    if (!!response) {
      yield put({
        type: GET_MOTIVATIONS_COMPLETE,
        payload: { motivationsData: response.data },
      });
      yield put(setLoading({ data: false }));
    } else {
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onGetMotivationsError({
          motivationsData: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onGetMotivationsError({
        motivationsData: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* orderPrognostogram({
  payload: { params, token, csrfToken, isChatrwd, navigateToDashboard },
}) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    yield put({
      type: ORDER_PROGNOSTOGRAM_START,
    });
    const { response, error } = yield call(order.orderPrognostogram, {
      params,
      token,
      csrfToken,
    });

    if (!!response) {
      yield put({
        type: ORDER_PROGNOSTOGRAM_COMPLETE,
      });
      if (isChatrwd) {
        navigateToDashboard?.(response.data?.task_ids);
      } else {
        yield put(setLoading({ data: false }));
        navigateToDashboard?.();
      }
    } else {
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onOrderPrognostogramError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onOrderPrognostogramError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getSearchQueryListDetails({ payload: { token } }) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    yield put({
      type: GET_SEARCH_QUERY_DETAILS_START,
    });
    const { response, error } = yield call(order.getSearchQueryListDetails, {
      token,
    });
    if (!!response) {
      yield put({
        type: GET_SEARCH_QUERY_DETAILS_COMPLETE,
        payload: { searchData: response.data },
      });
      yield put(setLoading({ data: false }));
    } else {
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onSearchQueryListError({
          searchData: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onSearchQueryListError({
        searchData: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getOrdersBySearch({
  payload: { token, params, orderIndexData, navigateToResult },
}) {
  try {
    yield put(resetData());
    yield put(setSearchOrderLoading(true));
    yield put({
      type: GET_SEARCH_DATA_START,
    });
    const { response, error } = yield call(order.getOrdersBySearch, {
      token,
      params,
      orderIndexData,
    });
    if (!!response) {
      yield put({
        type: GET_SEARCH_DATA_COMPLETE,
        payload: {
          isIndexSet:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? true
              : false,
          searchData:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? orderIndexData.orders
              : response.data.result,
          searchPageNumber:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? orderIndexData.searchPageNumber - 1
              : params.page,
          count: response.data.count,
          total_pages: response.data.total_pages,
        },
      });

      yield put(setSearchOrderLoading(false));
      const { scope, simple_search } = params;
      yield getSpecialtyListData({
        payload: { token, scope, search: simple_search.text },
      });
      yield getSearchQueryListDetails({ payload: { token } });
      navigateToResult?.();
    } else {
      yield put(setSearchOrderLoading(false));
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onSearchDataError({
          searchData: undefined,
          count: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onSearchDataError({
        searchData: undefined,
        count: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getOrderStatusCounts({ payload: { token } }) {
  try {
    yield put(resetData());
    yield put({
      type: GET_ORDER_STATUS_COUNT_START,
    });
    const { response, error } = yield call(order.getOrderStatusCounts, {
      token,
    });
    if (!!response) {
      yield put({
        type: GET_ORDER_STATUS_COUNT_COMPLETE,
        payload: { orderStatusData: response.data },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onOrdersStatusCountError({
          orderStatusData: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onOrdersStatusCountError({
        orderStatusData: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getPgPdfFile({ payload: { token, orderGuid, id_type } }) {
  try {
    yield put(resetData());
    yield put({
      type: GET_PROGNOSTOGRAM_PDF_START,
    });

    const { response, error } = yield call(order.getPgPdfBytes, {
      token,
      orderGuid,
      id_type,
    });
    if (!!response) {
      yield put({
        type: GET_PROGNOSTOGRAM_PDF_COMPLETE,
        payload: { pdfFile: response.data },
      });
      const { data } = response;
      let link = document.createElement('a');
      link.href = data;
      link.setAttribute('target', '_blank');
      link.click();
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onPgPdfFile({
          pdfFile: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onPgPdfFile({
        pdfFile: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* deleteOrder({ payload: { token, orderGuid, deleteOrderCallback } }) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    yield put({
      type: DELETE_ORDER_START,
    });
    const { response, error } = yield call(order.deleteOrder, {
      token,
      orderGuid,
    });
    if (!!response) {
      yield put({
        type: DELETE_ORDER_COMPLETE,
        payload: { data: response.data },
      });
      yield put(setLoading({ data: false }));
      deleteOrderCallback?.();
    } else {
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onDeleteOrderError({
          data: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
      deleteOrderCallback?.();
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onDeleteOrderError({
        data: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
    deleteOrderCallback?.();
  }
}

export function* modifyPendingOrder({
  payload: { params, token, orderGuid, navigateToDashboard },
}) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    yield put({
      type: MODIFY_PROGNOSTOGRAM_ORDER_START,
    });
    const { response, error } = yield call(order.modifyPendingOrder, {
      params,
      token,
      orderGuid,
    });

    if (!!response) {
      yield put({
        type: MODIFY_PROGNOSTOGRAM_ORDER_COMPLETE,
        payload: { questionData: response.data },
      });
      navigateToDashboard?.();
      yield put(setLoading({ data: false }));
    } else {
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onModifyPendingOrderError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onModifyPendingOrderError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getSpecialtyListData({ payload: { token, scope, search } }) {
  try {
    yield put(resetData());
    yield put(setLoading({ data: true }));
    yield put({
      type: GET_SPECIALTY_LIST_START,
    });
    const { response, error } = yield call(order.getSpecialtyList, {
      token,
      scope,
      search,
    });
    if (!!response) {
      yield put({
        type: GET_SPECIALTY_LIST_COMPLETE,
        payload: {
          specialtyData: [response.data],
        },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onGetSpecialtyListDataError({
          specialtyData: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
    yield put(setLoading({ data: false }));
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onGetSpecialtyListDataError({
        specialtyData: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* uploadOrderDocument({
  payload: {
    token,
    params,
    tokenExpiredCallback,
    fileDetails: { file, phiDisclaimerStatus },
  },
}) {
  try {
    yield put(resetData());
    yield put({
      type: UPLOAD_ORDER_DOCUMENT_START,
    });
    const { response, error } = yield call(order.uploadDocument, {
      token,
      params,
    });
    if (!!response) {
      yield put({
        type: UPLOAD_ORDER_DOCUMENT_COMPLETE,
        payload: {
          data: response.data,
        },
      });
      yield put({
        type: SET_UPLOADED_FILE,
        payload: {
          file_name: file.name,
          key_path: response.data.key_path,
          phi_disclaimer: phiDisclaimerStatus,
          is_uploaded: true,
        },
      });
    } else {
      if (error.status === 401) {
        tokenExpiredCallback();
      } else {
        yield put(
          onUploadOrderDocumentError({
            error: error?.data.message
              ? error?.data.message
              : orderFormErrorStr.commonError,
          }),
        );
      }
    }
  } catch (error) {
    yield put(
      onUploadOrderDocumentError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getOrderData({ payload: { token, guid } }) {
  try {
    yield put(resetData());
    yield put({
      type: GET_ORDER_BY_GUID_START,
    });
    const { response, error } = yield call(order.getOrder, {
      token,
      guid,
    });
    if (!!response) {
      yield put({
        type: GET_ORDER_BY_GUID_COMPLETE,
        payload: {
          data: response.data,
        },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onGetOrderError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetOrderError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getDownloadLink({
  payload: { token, guid, resource, tokenExpiredCallback },
}) {
  try {
    yield put({
      type: GET_DOWNLOAD_LINK_START,
    });
    const { response, error } = yield call(order.getDownloadLink, {
      token,
      guid,
      resource,
    });
    if (!!response) {
      yield put({
        type: GET_DOWNLOAD_LINK_COMPLETE,
        payload: {
          data: response.data,
          resource: resource,
        },
      });
    } else {
      if (error.status === 401) {
        yield call(tokenExpiredCallback());
      } else {
        yield put(
          onGetDownloadLinkError({
            resource: resource,
            error: error?.data.message
              ? error?.data.message
              : orderFormErrorStr.commonError,
          }),
        );
      }
    }
  } catch (error) {
    yield put(
      onGetDownloadLinkError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getResultThroughOpenAi({ payload: { token, params } }) {
  try {
    yield put({
      type: FEATCH_RESULT_THROUGH_OPEN_AI_START,
    });

    const { response, error } = yield call(order.getResultThroughOpenAi, {
      token,
      params,
    });

    if (!!response) {
      if (response?.data?.buttons?.PICOT_ACCURATE) {
        const picotAccurateButton = response.data.buttons['PICOT_ACCURATE'];
        delete response.data.buttons['PICOT_ACCURATE'];
        response.data.buttons['PICOT_ACCURATE'] = picotAccurateButton;
      }

      const { study_format = {}, buttons = {} } = response.data;

      yield put({
        type: FEATCH_RESULT_THROUGH_OPEN_AI_COMPLETE,
        payload: {
          data: {
            ...response.data,
            isEdit: false,
          },
          picoSummaryQuestion: study_format
            ? constructQuestionFromStudyFormat(study_format)
            : '',
        },
      });

      if (response?.data?.guid) {
        yield put({
          type: SET_PICO_GUID,
          payload: response.data.guid,
        });
      }
    } else {
      yield put(
        onGetResultThroughOpenAiError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetResultThroughOpenAiError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

function* startGetResultThroughOpenAi(payload) {
  const newTask = yield fork(getResultThroughOpenAi, payload);
  taskArray.push(newTask);
}

export function* fetchPicoToPhenotype({ payload: { token, params } }) {
  try {
    yield put({
      type: FEATCH_PICO_TO_PHENOTYPE_START,
    });
    const { response, error } = yield call(order.fetchPicoToPhenotype, {
      token,
      params,
    });

    if (!!response) {
      if (response?.data?.buttons?.PHENOTYPES_ACCURATE) {
        const picotAccurateButton = response.data.buttons['PHENOTYPES_ACCURATE'];
        delete response.data.buttons['PHENOTYPES_ACCURATE'];
        response.data.buttons['PHENOTYPES_ACCURATE'] = picotAccurateButton;
      }

      if (
        response?.data?.buttons &&
        Object.keys(response.data.buttons).length === 3 &&
        response.data.buttons.TRY_AGAIN &&
        response.data.buttons.START_OVER &&
        response.data.buttons.MANUALLY_EDIT_PICOT_ON_ERROR
      ) {
        response.data.message = response.message;
      }

      yield put({
        type: FEATCH_PICO_TO_PHENOTYPE_COMPLETE,
        payload: {
          data: response.data,
        },
      });
    } else {
      console.log('Phenotype error--', error);
      yield put(
        onFetchPicoToPhenotypeError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    console.log('Phenotype error--', error);
    yield put(
      onFetchPicoToPhenotypeError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

function* startFetchPicoToPhenotype(payload) {
  const newTask = yield fork(fetchPicoToPhenotype, payload);
  taskArray.push(newTask);
}

export function* savePicoOptions({
  payload: { token, params, isPICOSuggestionWorkflow },
}) {
  try {
    yield put({
      type: SAVE_PICO_OPTIONS_START,
    });

    const { response, error } = yield call(order.savePicoOptions, { token, params });

    const buttons = {
      SUGGEST_IMPROVEMENTS: Strings.suggestImprovements,
      MANUALLY_EDIT: Strings.manuallyEdit,
      REGENERATE_PICOT: Strings.regenerateStudy,
      START_OVER: Strings.startOver,
      PICOT_ACCURATE: Strings.studyAccurate,
    };

    if (response) {
      const studyFormat = { ...params?.study_format };

      const orderedStudyFormat = keyOrder.reduce((acc, key) => {
        if (studyFormat[key] !== undefined) {
          acc[key] = studyFormat[key];
        }
        return acc;
      }, {});

      yield put({
        type: SAVE_PICO_OPTIONS_COMPLETE,
        payload: {
          data: {
            ...response.data,
            isEdit: false,
            buttons,
            display_text_1: Strings.picot_with_suggestion_message,
            display_text_2: Strings.readyToSubmit,
            study_design: params?.study_design,
            study_format: {
              ...orderedStudyFormat,
            },
          },
        },
      });

      if (response?.data?.guid) {
        yield put({
          type: SET_PICO_GUID,
          payload: response.data.guid,
        });
      }
    } else {
      yield put(
        onSavePicoOptionsError({
          error: error?.data?.message || orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onSavePicoOptionsError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

function* startSavePicoOptions(payload) {
  const newTask = yield fork(savePicoOptions, payload);
  taskArray.push(newTask);
}

export function* fetchOrderTaskStatus({
  payload: { token, params, navigateToDashboard, errorCallback },
}) {
  try {
    yield put(setLoading({ data: true }));
    yield put({
      type: FEATCH_ORDER_TASK_STATUS_START,
    });
    const { response, error } = yield call(order.fetchOrderTaskStatus, {
      token,
      params,
    });

    if (!!response) {
      var checkOrderCompleted = false;
      for (let i = 0; i < response?.data.status?.length; i++) {
        const obj = response?.data.status[i];
        for (let key in obj) {
          if (obj.hasOwnProperty(key) && key === 'status' && obj[key] === 'Completed') {
            checkOrderCompleted = true;
          }
        }
      }

      yield put({
        type: FEATCH_ORDER_TASK_STATUS_COMPLETE,
        payload: {
          data: response.data,
        },
      });
      if (checkOrderCompleted) {
        yield put(setLoading({ data: false }));
        navigateToDashboard();
      }
    } else {
      yield put(setLoading({ data: false }));
      errorCallback();
      yield put(
        onFetchOrderTaskStatusError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    errorCallback();
    yield put(
      onFetchOrderTaskStatusError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getSuggestionsThroughOpenAi({ payload: { token, params } }) {
  try {
    yield put({
      type: FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_START,
    });
    const { response, error } = yield call(order.fetchPicoSuggestions, {
      token,
      params,
    });

    if (!!response) {
      const { data } = response;
      const hasSuggestions =
        Array.isArray(data.suggestions) && data.suggestions.length > 0;

      const suggestionObj = {
        ...data,
        multiSelect: hasSuggestions,
      };

      yield put({
        type: FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE,
        payload: {
          data: suggestionObj,
        },
      });
    } else {
      yield put({
        type: FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_ERROR,
        payload: {
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        },
      });
    }
  } catch (error) {
    yield put({
      type: FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_ERROR,
      payload: {
        error: error?.data.message ? error?.data.message : orderFormErrorStr.commonError,
      },
    });
  }
}

function* startGetSuggestionsThroughOpenAi(payload) {
  const newTask = yield fork(getSuggestionsThroughOpenAi, payload);
  taskArray.push(newTask);
}

export function* incorporateSuggestionsThroughOpenAi({ payload: { token, params } }) {
  try {
    yield put({
      type: INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_START,
    });
    const { response, error } = yield call(order.incorporateSuggestions, {
      token,
      params,
    });

    if (!!response) {
      if (response?.data?.buttons?.PICOT_ACCURATE) {
        const picotAccurateButton = response.data.buttons['PICOT_ACCURATE'];
        delete response.data.buttons['PICOT_ACCURATE'];
        response.data.buttons['PICOT_ACCURATE'] = picotAccurateButton;
      }

      const { study_format = {} } = response.data;
      yield put({
        type: INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE,
        payload: {
          data: {
            ...response.data,
            isEdit: false,
          },
          picoSummaryQuestion:
            study_format && Object.keys(study_format).length > 0
              ? constructQuestionFromStudyFormat(study_format)
              : '',
        },
      });
      if (response?.data?.guid) {
        yield put({
          type: SET_PICO_GUID,
          payload: response.data.guid,
        });
      }
    } else {
      yield put({
        type: INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_ERROR,
        payload: {
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        },
      });
    }
  } catch (error) {
    yield put({
      type: INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_ERROR,
      payload: {
        error: error?.data.message ? error?.data.message : orderFormErrorStr.commonError,
      },
    });
  }
}

function* startIncorporateSuggestionsThroughOpenAi(payload) {
  const newTask = yield fork(incorporateSuggestionsThroughOpenAi, payload);
  taskArray.push(newTask);
}

export function* getOrderNotificationDetails({ payload: { token, guid, isOrderPage } }) {
  try {
    yield put({
      type: GET_ORDER_NOTIFICATION_DETAILS_START,
    });
    const { response, error } = yield call(order.getOrder, {
      token,
      guid,
    });
    if (!!response) {
      yield put({
        type: GET_ORDER_NOTIFICATION_DETAILS_COMPLETE,
        payload: {
          data: response.data,
        },
      });
    } else {
      yield put({
        type: GET_ORDER_NOTIFICATION_DETAILS_ERROR,
        payload: {
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_ORDER_NOTIFICATION_DETAILS_ERROR,
      payload: {
        error: orderFormErrorStr.commonError,
      },
    });
  }
}

export function* syncModifiedPhenotypeData({ payload: { token, params } }) {
  try {
    yield put({
      type: SYNC_MODIFIED_PHENOTYPE_DATA_START,
    });
    const { response, error } = yield call(order.syncModifiedPhenotypeData, {
      token,
      params,
    });
    if (!!response) {
      yield put({
        type: SYNC_MODIFIED_PHENOTYPE_DATA_COMPLETE,
        payload: {
          data: response?.data?.user_phenotypes,
        },
      });

      if (response?.data?.ai_search_history_guid) {
        yield put({
          type: SET_PICO_GUID,
          payload: response.data.ai_search_history_guid,
        });
      }
    } else {
      const errorObj =
        error?.data?.errors && Object.keys(error.data.errors).length > 0
          ? error?.data?.errors
          : {};

      yield put({
        type: SYNC_MODIFIED_PHENOTYPE_DATA_ERROR,
        payload: {
          error: error?.data.message || orderFormErrorStr.commonError,
          errorObj,
        },
      });
    }
  } catch (error) {
    yield put({
      type: SYNC_MODIFIED_PHENOTYPE_DATA_ERROR,
      payload: {
        error: orderFormErrorStr.commonError,
      },
    });
  }
}

function* startSyncModifiedPhenotypeData(payload) {
  const newTask = yield fork(syncModifiedPhenotypeData, payload);
  taskArray.push(newTask);
}

export function* getStudyDetails({ payload: { token } }) {
  try {
    const { response, error } = yield call(order.getStudyDetails, {
      token,
    });

    if (!!response) {
      yield put({
        type: GET_STUDY_DETAILS_COMPLETE,
        payload: response,
      });
    } else {
      yield put({
        type: GET_STUDY_DETAILS_ERROR,
        payload: error?.data.message
          ? error?.data.message
          : orderFormErrorStr.commonError,
      });
    }
  } catch (error) {
    yield put(
      yield put({
        type: GET_STUDY_DETAILS_ERROR,
        payload: {
          error: orderFormErrorStr.commonError,
        },
      }),
    );
  }
}

export function* stopBackgroundTasks() {
  if (taskArray && taskArray.length) {
    for (let i = 0; i < taskArray.length; i++) {
      yield cancel(taskArray[i]);
    }
    taskArray = [];
  }
}

export function* fetchAiSearchResults({
  payload: { token, params, orderIndexData, navigateToResult },
}) {
  try {
    yield put(resetData());
    yield put(setSearchOrderLoading(true));
    yield put({
      type: GET_SEARCH_DATA_START,
    });
    const { response, error } = yield call(order.searchAiOrders, {
      token,
      params,
      orderIndexData,
    });
    if (!!response) {
      yield put({
        type: GET_SEARCH_DATA_COMPLETE,
        payload: {
          isIndexSet:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? true
              : false,
          searchData:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? orderIndexData.orders
              : response.data.result,
          searchPageNumber:
            orderIndexData !== undefined && Object.keys(orderIndexData).length !== 0
              ? orderIndexData.searchPageNumber - 1
              : params.page,
          count: response.data.count,
          total_pages: response.data.total_pages,
          ai_results: response?.data?.ai_results || false,
        },
      });

      yield put(setSearchOrderLoading(false));
      const { scope, simple_search } = params;
      yield getSpecialtyListData({
        payload: { token, scope, search: simple_search.text },
      });
      yield getSearchQueryListDetails({ payload: { token } });
      navigateToResult?.();
    } else {
      yield put(setSearchOrderLoading(false));
      yield put(setLoading({ data: false }));
      //Show error boundary if error is 500 or other
      yield put(
        onSearchDataError({
          searchData: undefined,
          count: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(setLoading({ data: false }));
    yield put(
      onSearchDataError({
        searchData: undefined,
        count: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

function* startAiSearch(payload) {
  const newTask = yield fork(fetchAiSearchResults, payload);
  taskArray.push(newTask);
}

function* watchGetStudyDetails() {
  yield takeLatest(GET_STUDY_DETAILS, getStudyDetails);
}

function* watchStopBackgroundTasks() {
  yield takeLatest(ordersActions.STOP_BACKGROUND_SYNC, stopBackgroundTasks);
}

const onFetchOrderTaskStatusError = (payload = {}) => ({
  type: FEATCH_ORDER_TASK_STATUS_ERROR,
  payload,
});

const onSavePicoOptionsError = (payload = {}) => ({
  type: SAVE_PICO_OPTIONS_ERROR,
  payload,
});

const onFetchPicoToPhenotypeError = (payload = {}) => ({
  type: FEATCH_PICO_TO_PHENOTYPE_ERROR,
  payload,
});

const onGetResultThroughOpenAiError = (payload = {}) => ({
  type: FEATCH_RESULT_THROUGH_OPEN_AI_ERROR,
  payload,
});

const onModifyPendingOrderError = (payload = {}) => ({
  type: MODIFY_PROGNOSTOGRAM_ORDER_ERROR,
  payload,
});

const onPgPdfFile = (payload = {}) => ({
  type: GET_PROGNOSTOGRAM_PDF_ERROR,
  payload,
});

const onDeleteOrderError = (payload = {}) => ({
  type: DELETE_ORDER_ERROR,
  payload,
});

const onOrdersStatusCountError = (payload = {}) => ({
  type: GET_ORDER_STATUS_COUNT_ERROR,
  payload,
});

const onSearchDataError = (payload = {}) => ({
  type: GET_SEARCH_DATA_ERROR,
  payload,
});

const onSearchQueryListError = (payload = {}) => ({
  type: GET_SEARCH_QUERY_DETAILS_ERROR,
  payload,
});

const onGetQuestionError = (payload = {}) => ({
  type: GET_QUESTION_TYPES_ERROR,
  payload,
});

const onGetMotivationsError = (payload = {}) => ({
  type: GET_MOTIVATIONS_ERROR,
  payload,
});

const onGetOrderDetailsError = (payload = {}) => ({
  type: GET_ORDER_DETAILS_ERROR,
  payload,
});

const onOrderPrognostogramError = (payload = {}) => ({
  type: ORDER_PROGNOSTOGRAM_ERROR,
  payload,
});

const onGetSpecialtyListDataError = (payload = {}) => ({
  type: GET_SPECIALTY_LIST_ERROR,
  payload,
});

const onGetOrderError = (payload = {}) => ({
  type: GET_ORDER_BY_GUID_ERROR,
  payload,
});

const onUploadOrderDocumentError = (payload = {}) => ({
  type: UPLOAD_ORDER_DOCUMENT_ERROR,
  payload,
});

const onGetDownloadLinkError = (payload = {}) => ({
  type: GET_DOWNLOAD_LINK_ERROR,
  payload,
});

function* watchFetchOrderTaskStatus() {
  yield takeLatest(FEATCH_ORDER_TASK_STATUS, fetchOrderTaskStatus);
}

function* watchSavePicoOptions() {
  yield takeLatest(SAVE_PICO_OPTIONS, startSavePicoOptions);
}

function* watchFetchPicoToPhenotype() {
  yield takeLatest(FEATCH_PICO_TO_PHENOTYPE, startFetchPicoToPhenotype);
}

function* watchGetResultThroughOpenAi() {
  yield takeLatest(FEATCH_RESULT_THROUGH_OPEN_AI, startGetResultThroughOpenAi);
}

function* watchGetDownloadLink() {
  yield takeLatest(GET_DOWNLOAD_LINK, getDownloadLink);
}

function* watchUploadOrderDocument() {
  yield takeLatest(UPLOAD_ORDER_DOCUMENT, uploadOrderDocument);
}

function* watchGetOrderData() {
  yield takeLatest(GET_ORDER_BY_GUID, getOrderData);
}

function* watchGetSpecialtyListData() {
  yield takeLatest(GET_SPECIALTY_LIST, getSpecialtyListData);
}

function* watchModifyPendingOrder() {
  yield takeLatest(MODIFY_PROGNOSTOGRAM_ORDER, modifyPendingOrder);
}

function* watchRequestOrderDeatils() {
  yield takeLatest(GET_ORDER_DETAILS, getOrderDeatils);
}

function* watchRequestOrderPrognostogram() {
  yield takeLatest(ORDER_PROGNOSTOGRAM, orderPrognostogram);
}

function* watchRequestQuestionTypes() {
  yield takeLatest(GET_QUESTION_TYPES, getQuestionTypes);
}

function* watchRequestMotivations() {
  yield takeLatest(GET_MOTIVATIONS, getMotivations);
}

function* watchgetSearchQueryListDetails() {
  yield takeLatest(GET_SEARCH_QUERY_DETAILS, getSearchQueryListDetails);
}

function* watchGetOrdersBySearch() {
  yield takeLatest(GET_SEARCH_DATA, getOrdersBySearch);
}

function* watchGetOrderStatusCounts() {
  yield takeLatest(GET_ORDER_STATUS_COUNT, getOrderStatusCounts);
}

function* watchGetPgPdf() {
  yield takeLatest(GET_PROGNOSTOGRAM_PDF, getPgPdfFile);
}

function* watchDeleteOrder() {
  yield takeLatest(DELETE_ORDER, deleteOrder);
}

function* watchGetSuggestionsThroughOpenAi() {
  yield takeLatest(FEATCH_SUGGESTIONS_THROUGH_OPEN_AI, startGetSuggestionsThroughOpenAi);
}

function* watchIncorporateSuggestionsThroughOpenAi() {
  yield takeLatest(
    INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI,
    startIncorporateSuggestionsThroughOpenAi,
  );
}

function* watchGetOrderNotificationDetails() {
  yield takeLatest(GET_ORDER_NOTIFICATION_DETAILS, getOrderNotificationDetails);
}

function* watchUpdatePhenotypes() {
  yield takeLatest(SYNC_MODIFIED_PHENOTYPE_DATA, startSyncModifiedPhenotypeData);
}

function* watchAiSearch() {
  yield takeLatest(ordersActions.AI_SEARCH, startAiSearch);
}

export const sagas = [
  watchRequestOrderDeatils,
  watchRequestQuestionTypes,
  watchRequestMotivations,
  watchRequestOrderPrognostogram,
  watchgetSearchQueryListDetails,
  watchGetOrdersBySearch,
  watchGetOrderStatusCounts,
  watchGetPgPdf,
  watchDeleteOrder,
  watchModifyPendingOrder,
  watchGetSpecialtyListData,
  watchGetOrderData,
  watchUploadOrderDocument,
  watchGetDownloadLink,
  watchGetResultThroughOpenAi,
  watchFetchPicoToPhenotype,
  watchSavePicoOptions,
  watchFetchOrderTaskStatus,
  watchGetSuggestionsThroughOpenAi,
  watchIncorporateSuggestionsThroughOpenAi,
  watchGetOrderNotificationDetails,
  watchUpdatePhenotypes,
  watchGetStudyDetails,
  watchStopBackgroundTasks,
  watchAiSearch,
];
