const aiButtonsEnum = Object.freeze({
  PICOT_ACCURATE: 'PICOT_ACCURATE',
  SUGGEST_IMPROVEMENTS: 'SUGGEST_IMPROVEMENTS',
  MANUALLY_EDIT: 'MANUALLY_EDIT',
  REGENERATE_PICOT: 'REGENERATE_PICOT',
  START_OVER: 'START_OVER',
  PHENOTYPES_ACCURATE: 'PHENOTYPES_ACCURATE',
  MANUALLY_EDIT_PICO: 'MANUALLY_EDIT_PICO',
  GENERATE_PICO: 'GENERATE_PICO',
  MANUALLY_ENTER_PICO: 'MANUALLY_ENTER_PICO',
  SAVE_PICO: 'SAVE_PICO',
  CANCEL_PICO: 'CANCEL_PICO',
  EDIT_PHENOTYPES: 'EDIT_PHENOTYPES',
  UNDO_PHENOTYPE_EDITS: 'UNDO_PHENOTYPE_EDITS',
  KEEP_ORIGINAL: 'KEEP_ORIGINAL',
  REGENERATE_SUGGESTIONS: 'REGENERATE_SUGGESTIONS',
  TRY_AGAIN: 'TRY_AGAIN',
  MANUALLY_SAVE_PICO: 'MANUALLY_SAVE_PICO',
  CANCEL_PICO_EDIT: 'CANCEL_PICO_EDIT',
  SAVE_PHENOTYPE_TO_PICO: 'SAVE_PHENOTYPE_TO_PICO',
  MANUALLY_EDIT_QUESTION: 'MANUALLY_EDIT_QUESTION',
  CANCEL_CURRENT_FORMAT_EDIT: 'CANCEL_CURRENT_FORMAT_EDIT',
  SAVE_CURRENT_FORMAT_EDIT: 'SAVE_CURRENT_FORMAT_EDIT',
  MANUALLY_EDIT_PICOT_ON_ERROR: 'MANUALLY_EDIT_PICOT_ON_ERROR',
  INCORPORATE_CHANGES: 'INCORPORATE_CHANGES',
  SUGGESTIONS_MANUALLY_EDIT: 'SUGGESTIONS_MANUALLY_EDIT',
  MANUALLY_EDIT_ON_INCORPORATION_ERROR: 'MANUALLY_EDIT_ON_INCORPORATION_ERROR',
});

export { aiButtonsEnum };
