import React from 'react';
import { useOrdersPagedata } from '../OrderPageContext';
import { FreeTextOrder } from '../freetext';
import { PicotOrder } from '../picot';
import { ChartRwdOrder } from '../chartrwd';
import Header from './Header';
import { labels } from 'constants/labels';
import orderFormErrorStr from 'constants/errorStrings';

function OrderSwitcher({
  isSidebarHidden,
  toggleSidebar,
  onOrderSuccessCallbackForChatrwd,
}) {
  const {
    ordersPageData: { questionTypeSelection, errorsObject },
  } = useOrdersPagedata();
  const hasErrors = Object.values(errorsObject).some((value) => value !== '');

  const renderOrderComponent = () => {
    switch (questionTypeSelection) {
      case 1:
        return <FreeTextOrder />;
      case 2:
        return <PicotOrder />;
      default:
        return (
          <ChartRwdOrder
            onOrderSuccessCallbackForChatrwd={onOrderSuccessCallbackForChatrwd}
          />
        );
    }
  };

  return (
    <div className={`order-right-section p-md-6 p-3 ${isSidebarHidden ? 'w-100' : ''}`}>
      {isSidebarHidden && (
        <div className="collapse-btn position-static toggle-btn" onClick={toggleSidebar}>
          Expand
        </div>
      )}
      <div className="order-section-container clinical-question-container mb-3 pb-5">
        <Header />
        {renderOrderComponent()}

        {hasErrors && questionTypeSelection !== 3 && (
          <ul className="ah-alert ah-alert-error list-bullet order-page-error-container mt-2">
            {Object.entries(errorsObject).map(([key, value]) => {
              if (!value) return null;
          
              return (
                <li key={`${key}-${value}`} className="color-error txt size-13 list-spacing">
                  {value === orderFormErrorStr.commonError ? (
                    <span>
                      Something went wrong. Please{' '}
                      <a href={`mailto:${labels.supportEmail}`} className="color-error">contact support</a>{' '}
                      for help!
                    </span>
                  ) : (
                    value
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

export default OrderSwitcher;
