import React from 'react';
import { Frame } from 'components/Frame';
import Suggestion from './Suggestion';
import { Button } from 'components/button';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { ReactComponent as CheckGray } from 'assets/images/CheckGray.svg';
import { ReactComponent as Check } from 'assets/images/Check.svg';
import StudyDetailsSummary from '../studydetails/StudyDetailsSummary';

function Suggestions({
  suggestions,
  optionIndex,
  studyFormatObject,
  selectedSuggestionsOptions,
}) {
  const {
    buttons = {
      TRY_AGAIN: 'Try Again',
      START_OVER: 'Start Over',
    },
  } = studyFormatObject;

  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
    handleButtonAction,
  } = useOrdersPagedata();

  const { checkedSuggestionsOptions } = chatRWD;
  const checkedOptions = checkedSuggestionsOptions[optionIndex] || [];

  function handleClick({ key, value }) {
    if (key !== aiButtonsEnum.TRY_AGAIN) {
      dispatchOrderPageAction({
        type: 'order/setSuggestionsOptions',
        payload: { option: value },
      });
    }

    dispatchOrderPageAction({
      type: 'order/setUserAction',
      payload: {
        userAction: key,
        options: checkedOptions,
      },
    });

    handleButtonAction({
      key: key,
      options: checkedOptions,
      index: optionIndex,
    });
  }

  return (
    <>
      <Frame>
        <Frame.Aside></Frame.Aside>
        <StudyDetailsSummary
          studyFormatObject={studyFormatObject}
          optionIndex={optionIndex}
        >
          <Frame.Body>
            {suggestions &&
              Array.isArray(suggestions) &&
              suggestions.length > 0 &&
              suggestions.map((suggestion, index) => (
                <div className="mb-1 detail-item" key={suggestion.id}>
                  <Suggestion
                    suggestion={suggestion}
                    index={index}
                    optionIndex={optionIndex}
                  />
                </div>
              ))}
          </Frame.Body>
        </StudyDetailsSummary>
      </Frame>
      <Frame.Footer>
        <div className="flex-wrap flex-justify-end green-border d-flex gap-10 w-100">
          {selectedSuggestionsOptions?.option ? (
            <Button label={selectedSuggestionsOptions?.option} icon={<Check />} cursor='not-allowed' />
          ) : (
            buttons &&
            Object.entries(buttons).map(([key, value], index) => (
              <Button
                key={value}
                outlined={!selectedSuggestionsOptions?.option ? true : false}
                label={value}
                onClick={() => handleClick({ key, value })}
                disabled={
                  key === aiButtonsEnum.INCORPORATE_CHANGES && !checkedOptions.length
                }
                icon={key === aiButtonsEnum.INCORPORATE_CHANGES && <CheckGray />}
              />
            ))
          )}
        </div>
      </Frame.Footer>
    </>
  );
}

export default Suggestions;
