import React, { useEffect, useRef } from 'react';
import { truncateText } from 'utils/strings';
import atroposLogo from '../../assets/images/atropos_fav_logo.png';
import styled from 'styled-components';
import { OverlayPanel } from 'primereact/overlaypanel';
import TruncatedText from 'components/truncatedText/TruncatedText';
import { labels } from 'constants/labels';
import { Avatar } from 'components/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { useToken } from 'hooks/useToken';
import { useDispatch, useSelector } from 'react-redux';
import { getPrognostogramPdf } from 'redux/modules/orderDetails/actions';
import AuthLoader from 'components/AuthLoader';

const StyledDiv = styled.div`
  background: var(--bg-white);
  padding: 11px;
  border: 1px solid var(--border-neutral-200);
  border-radius: var(--border-radius-8);
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease, transform 0.3s ease;
  gap: 10px;
  min-width: 20%;
  &:hover {
    background-color: #e2e8e8;
    border: 1px solid #dcdcdc;
  }
  box-sizing: border-box;
  flex-wrap: wrap;
  flex: 1 0 100%;
  max-width: 100%;
  position: relative;
  cursor: pointer;

  @media (min-width: 768px) {
    flex: 1 0 calc(50% - 10px);
    max-width: ${(props) => props.width || 'calc(50% - 10px)'};
  }

  @media (min-width: 1024px) {
    flex: 1 0 calc(33.33% - 10px);
  }

  @media (min-width: 1200px) {
    flex: 1 0 calc(25% - 10px);
    max-width: ${(props) => props.width || 'calc(25% - 10px)'};
  }
`;

const StyledDot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: #b6c5c5;
`;

const StyledImage = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 50%;
`;

const StyledLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .authloader {
    background-color: transparent !important;
  }
`;

function Source({
  source,
  onClick,
  activeIndex,
  index,
  showInfoOverlay = true,
  ...props
}) {
  const op = useRef(null);
  const token = useToken();
  const dispatch = useDispatch();
  const { isPdfLoading } = useSelector(({ orderDetails }) => orderDetails);

  const title = truncateText(source?.title, 43);
  const org = 'Atropos Health'.trim();

  const handleMouseEnter = (event) => {
    if (op.current) {
      op.current.toggle(event);
    }
  };

  const handleMouseLeave = (event) => {
    if (op.current) {
      op.current.hide();
    }
  };

  const handleClick = () => {
    if (source?.name !== labels?.otherSources) {
      dispatch(
        getPrognostogramPdf({
          token,
          orderGuid: source?.document_uri,
          id_type: 'case_uuid',
        }),
      );
    }
    onClick();
  };

  return (
    <>
      <StyledDiv
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        width={props.cardwidth}
      >
        <div className="txt w-700 lh-22 text-black-900">{source?.name}</div>
        {props.isModal ? (
          <div className="txt lh-19 size-14 w-700 text-black-900">{title}</div>
        ) : (
          <div className="txt lh-19 size-14 text-black-900 break-word">{title}</div>
        )}

        {props.isModal && (
          <div className="txt lh-19 size-14 text-black-800">
            {truncateText(source?.content, 300)}
          </div>
        )}

        <div className="d-flex align-items-center gap-10 w-100">
          {source?.avatars?.length > 0 ? (
            <AvatarGroup className="ah-avatar-group">
              {source?.avatars?.map((avatar) => (
                <Avatar image={atroposLogo} shape="circle" />
              ))}
            </AvatarGroup>
          ) : (
            <Avatar
              className="row-flex flex-shrink-0"
              image={atroposLogo}
              shape="circle"
            />
          )}

          {source?.name !== labels?.otherSources && (
            <>
              <TruncatedText
                text={org}
                width={props.truncatedtextwidth || '55'}
                className="txt lh-19 size-14 text-black-900"
              />
              <StyledDot></StyledDot>
              <div className="txt lh-19 size-14 text-black-900 ">1</div>
            </>
          )}

          {isPdfLoading && activeIndex === index && showInfoOverlay && (
            <StyledLoaderContainer>
              <AuthLoader fullScreen={false} />
            </StyledLoaderContainer>
          )}
        </div>

        {showInfoOverlay && (
          <OverlayPanel
            hide
            ref={op}
            style={{
              backgroundColor: 'var(--bg-white)',
              border: '1px solid var(--border-neutral-200)',
              borderRadius: '12px',
              padding: '16px',
              maxWidth: '400px',
              maxHeight: '350px',
              overflow: 'auto',
            }}
          >
            <div className="d-flex flex-column gap-10">
              <div className="d-flex align-items-center gap-10 w-100">
                <StyledImage src={atroposLogo} alt="close-icon" />
                <div className="txt size-14 lh-19 text-black-900">Atropos Health</div>
              </div>

              <div className="txt w-400 lh-22 size-14 text-black-900">
                {source?.title}
              </div>
              <div className="txt lh-19 size-14 text-black-800">{source?.content}</div>
            </div>
          </OverlayPanel>
        )}
      </StyledDiv>
    </>
  );
}

export default Source;
