import React, { useState, useCallback, useEffect } from 'react';
import { IoMdSearch } from 'react-icons/io';
import { TextInput } from 'components/form-input';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getSearchData,
  setSearchQueryText,
  setSpecialtyText,
  getSpecialtyList,
  resetOrderIndexData,
  aiSearch,
  stopBackgroundSync,
} from 'redux/modules/orderDetails/actions';
import {
  getSearchSuggestion,
  clearSearchTermData,
} from 'redux/modules/searchOrder/actions';
import AuthLoader from 'components/AuthLoader';
import { AiOutlineClockCircle } from 'react-icons/ai';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { ButtonCustom } from 'components/form-input/Button';
import orderScopeEnum from '../constants/enum/orderScopeEnum';
import Switch from 'react-switch';
import debounce from 'lodash.debounce';
import { ImInfo } from 'react-icons/im';
import { getRawToken } from '../utils/validations';
import { useMixpanel } from 'react-mixpanel-browser';
import { ORDER_PAGINATION_SIZE } from '../constants/Constants';
import { SearchField } from 'features/search';
import { labels } from 'constants/labels';
import { ReactComponent as AiMagicpen } from 'assets/images/AiMagicpen.svg';
import { ReactComponent as Search } from 'assets/images/AiSearch.svg';

const SearchForm = ({ handleSearchClick }) => {
  let history = useHistory();
  const mixpanel = useMixpanel();
  const location = useLocation();
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOpenSearchQueries, setOpenSearchQueries] = useState(false);
  const [isShowAllSearchTerms, setIsShowAllSearchTerms] = useState(false);
  const [showPopularAndRecentSearch, setShowPopularAndRecentSearch] = useState(true);
  const {
    searchQueryListData,
    isOrderLoading,
    getSearchQueryDeatilsError,
    orderScopeData,
    searchData,
    specialtyTextData,
    isSearchOrderLoading,
  } = useSelector(({ orderDetails }) => orderDetails);
  const [searchText, setsearchText] = useState(
    searchData?.searchText ? searchData?.searchText : '',
  );
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(
    searchData?.advancedSearchValue ? searchData?.advancedSearchValue : false,
  );
  const { searchTerms, waitingForSearchTerms } = useSelector(
    ({ searchOrder }) => searchOrder,
  );
  const debouncedSave = useCallback(
    debounce((newValue) => getSuggestions(newValue), 1000),
    [],
  );

  useEffect(() => {
    dispatch(clearSearchTermData());
  }, [dispatch]);

  const getSuggestions = async (word) => {
    if (word) {
      const token = await getIdTokenClaims();
      dispatch(
        getSearchSuggestion({
          params: {
            search_term: word,
          },
          token: getRawToken(token),
        }),
      );
    } else {
      setShowPopularAndRecentSearch(true);
      setIsShowAllSearchTerms(false);
      dispatch(clearSearchTermData());
    }
  };

  const getFiltersListData = async (scope, searchText) => {
    const token = await getIdTokenClaims();
    dispatch(
      getSpecialtyList({
        token: getRawToken(token),
        search: searchText,
        scope: scope,
      }),
    );
  };

  const searchOrders = async (search_query = searchText) => {
    dispatch(resetOrderIndexData({}));
    dispatch(stopBackgroundSync());
    !!handleSearchClick && handleSearchClick();
    setsearchText(search_query);
    dispatch(
      setSearchQueryText({
        searchText: search_query,
        pageNumber: 1,
        isSearchFromHome: true,
        advancedSearchValue: isAdvancedSearch,
      }),
    );

    setOpenSearchQueries(false);
    const token = await getIdTokenClaims();
    dispatch(
      getSearchData({
        params: {
          simple_search: {
            text: search_query,
          },
          advance_search: {},
          sort: 'newest-first',
          size: ORDER_PAGINATION_SIZE,
          page: 1,
          specialty: specialtyTextData ? specialtyTextData?.specialtyText : '',
          dataSource: '',
          motivation: '',
          scope: orderScopeData?.orderScope
            ? orderScopeData.orderScope
            : orderScopeEnum.PERSONAL,
        },
        advancedSearchValue: isAdvancedSearch,
        token: getRawToken(token),
        navigateToResult: navigateToResult(search_query),
      }),
    );
  };

  const searchAIOrders = async (search_query = searchText) => {
    dispatch(resetOrderIndexData({}));
    dispatch(stopBackgroundSync());
    !!handleSearchClick && handleSearchClick();
    setsearchText(search_query);
    dispatch(
      setSearchQueryText({
        searchText: search_query,
        pageNumber: 1,
        isSearchFromHome: true,
        advancedSearchValue: isAdvancedSearch,
      }),
    );

    setOpenSearchQueries(false);
    const token = await getIdTokenClaims();
    dispatch(
      aiSearch({
        params: {
          simple_search: {
            text: search_query,
          },
          advance_search: {},
          sort: 'newest-first',
          size: ORDER_PAGINATION_SIZE,
          page: 1,
          specialty: specialtyTextData ? specialtyTextData?.specialtyText : '',
          dataSource: '',
          motivation: '',
          scope: orderScopeData?.orderScope
            ? orderScopeData.orderScope
            : orderScopeEnum.PERSONAL,
        },
        advancedSearchValue: isAdvancedSearch,
        token: getRawToken(token),
        navigateToResult: navigateToResult(search_query),
      }),
    );
  };

  const navigateToResult = (search_query) => {
    setsearchText(search_query);
    history.push({
      pathname: orderScopeData?.orderScope
        ? orderScopeData.orderScope === orderScopeEnum.PERSONAL
          ? urlConstantsEnum.SEARCH_RESULT
          : urlConstantsEnum.LIBRARY_SEARCH_RESULT
        : urlConstantsEnum.SEARCH_RESULT,
      state: {
        searchQuery: search_query,
        advancedSearchValue: isAdvancedSearch,
      },
    });
  };
  const handleSwitchChange = () => {
    setIsAdvancedSearch(!isAdvancedSearch);
  };

  //handle change
  const onSearchTextChange = (e) => {
    setsearchText(e.currentTarget.value);
    //debouncedSave(e.currentTarget.value);
  };

  const setSearchQueriesState = () => {
    if (!isOpenSearchQueries) {
      setOpenSearchQueries(true);
    }
  };

  const onClickOfSearch = (e) => {
    e?.stopPropagation();
    if (searchText.trim().length > 0) {
      searchOrders(searchText);
    } else {
      goTodashboard();
    }
  };

  const checkIfEnterKeyPressed = (e) => {
    if (e.charCode === 13) {
      mixpanel.track('Search button clicked', {
        search_text_value: searchText,
      });
      onClickOfSearch(e);
    }
  };

  function getSearchQueriesList(searchList) {
    if (
      !orderScopeData?.orderScope ||
      orderScopeData?.orderScope === orderScopeEnum.PERSONAL
    ) {
      const filteredList = searchList.filter(
        (item) => item.search_type !== labels.ai_search,
      );
      return filteredList;
    }
    return searchList;
  }

  const getSearchList = (searchList, searchQuery) => {
    const list = getSearchQueriesList(searchList);
    const listItems = list.slice(0, 3).map((searchText, index) => {
      if (
        orderScopeData?.orderScope &&
        orderScopeData?.orderScope === orderScopeEnum.LIBRARY
      ) {
        return (
          <li
            key={index + '-' + searchText.search_query}
            className="d-flex flex-content-center my-3"
            id={`list-${searchQuery}-${index}`}
            onClick={(e) => {
              mixpanel.track('Search button clicked', { search_text_value: searchText });
              if (searchText?.search_type == labels?.simpleSearch) {
                searchOrders(searchText.search_query);
              } else {
                searchAIOrders(searchText.search_query);
              }
            }}
          >
            <div className="flex-self-center d-flex">
              {searchText?.search_type === labels.simpleSearch ? (
                <Search />
              ) : (
                <AiMagicpen style={{ height: '20px', widtht: '20px' }} />
              )}
            </div>
            <div className="ml-2 flex-self-center d-flex flex-items-center flex-content-center">
              {searchText.search_query}
            </div>
          </li>
        );
      } else {
        return (
          <li
            key={index + '-' + searchText.search_query}
            className="d-flex flex-content-center my-3"
            id={`list-${searchQuery}-${index}`}
            onClick={(e) => {
              mixpanel.track('Search button clicked', { search_text_value: searchText });
              searchOrders(searchText.search_query);
            }}
          >
            <div className="flex-self-center d-flex">
              <Search />
            </div>
            <div className="ml-2 flex-self-center d-flex flex-items-center flex-content-center">
              {searchText.search_query}
            </div>
          </li>
        );
      }
    });
    return <ul>{listItems}</ul>;
  };

  const goTodashboard = () => {
    dispatch(resetOrderIndexData({}));
    if (searchText.trim() === '') {
      if (history.location.pathname.includes('search')) {
        history.push(
          orderScopeData?.orderScope === 'personal'
            ? urlConstantsEnum.DASHBOARD
            : urlConstantsEnum.LIBRARY_DASHBOARD,
        );
      }
    }
  };

  const onClockOfOverlay = () => {
    dispatch(resetOrderIndexData({}));
    setOpenSearchQueries(false);
    if (searchText.trim()) {
      mixpanel.track('Search button clicked', { search_text_value: searchText });
      searchOrders(searchText);
    } else {
      dispatch(setSearchQueryText({}));
      goTodashboard();
    }

    dispatch(stopBackgroundSync());
  };

  const onClickOfClear = () => {
    dispatch(stopBackgroundSync());
    setsearchText('');
    setOpenSearchQueries(false);
    setShowPopularAndRecentSearch(true);
    setIsShowAllSearchTerms(false);
    dispatch(clearSearchTermData());
    dispatch(setSearchQueryText({}));
    dispatch(setSpecialtyText({}));
    dispatch(resetOrderIndexData({}));
    history.push(
      orderScopeData?.orderScope === 'personal'
        ? urlConstantsEnum.DASHBOARD
        : urlConstantsEnum.LIBRARY_DASHBOARD,
    );
  };

  const onClickOfLoadMore = () => {
    setShowPopularAndRecentSearch(false);
    setIsShowAllSearchTerms(true);
  };

  function handleSearch({ key }) {
    if (searchText.trim().length > 0) {
      switch (key) {
        case labels.aiSearch:
          if (!isSearchOrderLoading) searchAIOrders(searchText);
          break;
        case labels.regularSearch:
          if (!isSearchOrderLoading) searchOrders(searchText);
          break;
        case labels.clear:
          onClickOfClear();
          break;
        default:
          return;
      }
    } else {
      goTodashboard();
    }
  }

  return (
    <div className="position-relative">
      <SearchField
        placeholder="Enter your search"
        value={searchText}
        onIconClick={handleSearch}
        onChange={(e) => onSearchTextChange(e)}
        showclear={searchText ? true : false}
        onClick={(e) => setSearchQueriesState()}
        scope={orderScopeData?.orderScope}
      />
      <div
        className={`search-input d-flex flex-justify-start mt-1 ${
          isOpenSearchQueries ? 'search-view' : ''
        } ${searchText ? 'search-view-container' : ''}`}
      >
        {/* <div className="search-button d-lg-none d-block">
          <div className="d-flex flex-content-center flex-justify-center search-icon-button">
            <IoMdSearch />
          </div>
        </div> */}
        {/* <TextInput
          placeholder="Enter your search"
          value={searchText}
          onChange={(e) => onSearchTextChange(e)}
          onClick={(e) => setSearchQueriesState()}
          onKeyPress={(e) => checkIfEnterKeyPressed(e)}
          id={'input-search-text'}
        /> */}

        {/* <div className="search-button position-relative clear-btn-container">
          {searchText && (
            <ButtonCustom
              cssClass="position-absolute clear-btn"
              onClick={(e) => onClickOfClear(e)}
              buttonAction={'Clear search text button clicked'}
              actionLabel={'Searched text cleared'}
              id={'btn-search-text-clear-button'}
            >
              X
            </ButtonCustom>
          )}
          <ButtonCustom
            cssClass="d-lg-flex flex-content-center flex-justify-center search-icon-button d-none"
            onClick={(e) => onClickOfSearch(e)}
            buttonAction={'Search button clicked'}
            actionValue={searchText}
            id={'btn-search-button'}
          >
            <IoMdSearch className="d-flex flex-self-center" />
            <span className="ml-1">Search</span>
          </ButtonCustom>
        </div> */}
        {/* {isOpenSearchQueries || searchText !== '' ? (
          <div
            className="d-flex-toggle d-flex flex-justify-center"
            onKeyPress={(e) => checkIfEnterKeyPressed(e)}
          >
            <div className="d-flex picot-switch">
              <div className="flex-self-center picot-span d-flex">
                Advanced Search
                <div className="tooltip-container flex-self-centesr">
                  <div className={showTooltip ? 'tooltip-box visible' : 'tooltip-box'}>
                    Instead of searching for an exact match, Advanced Search (beta)
                    searches for related concepts and synonyms.
                    <span className="tooltip-arrow" />
                  </div>
                  <div
                    className="tooltip-button d-flexs"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={() => setShowTooltip(!showTooltip)}
                  >
                    <ImInfo />
                  </div>
                </div>
              </div>

              <Switch
                checkedIcon={false}
                onColor="#0D3F3C"
                offColor="#e5e5e5"
                height={20}
                width={30}
                uncheckedIcon={true}
                onChange={handleSwitchChange}
                checked={isAdvancedSearch}
                className="flex-self-center"
              />
            </div>
          </div>
        ) : (
          ''
        )} */}
      </div>
      {searchQueryListData?.popular_searches?.length > 0 && isOpenSearchQueries && (
        <div className="search-queries-container">
          <div className="search-overlay" onClick={() => onClockOfOverlay()}></div>
          <div
            className={`search-queries ${isShowAllSearchTerms ? 'all-search-terms' : ''}`}
          >
            <div className="xtSCL"></div>
            {isOrderLoading ? (
              <div className="position-relative">
                <AuthLoader fullScreen={false} />
              </div>
            ) : (
              <div className="mx-3 my-md-3 my-1">
                {/* {waitingForSearchTerms ? (
                  <div className="position-relative">
                    <AuthLoader fullScreen={false} />
                  </div>
                ) : (
                  <div>
                    {searchTerms?.length > 0 ? (
                      <div className="search-type-section">
                        <div className="my-3 suggestion-title">Did you mean</div>
                        <ul>
                          {searchTerms?.length > 0 &&
                            searchTerms
                              ?.slice(0, !isShowAllSearchTerms ? 5 : searchTerms.length)
                              .map((option, index) => (
                                <li
                                  className="d-flex flex-content-center my-3"
                                  key={`${option.preferredTerm}-${index}`}
                                  onClick={(e) => searchOrders(option.preferredTerm)}
                                >
                                  {option.preferredTerm}
                                </li>
                              ))}
                        </ul>
                        {searchTerms?.length > 5 && !isShowAllSearchTerms ? (
                          <ButtonCustom
                            cssClass="link link-btn"
                            onClick={(e) => onClickOfLoadMore(e)}
                          >
                            Load More
                          </ButtonCustom>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )} */}
                {!!getSearchQueryDeatilsError ? (
                  <div className="text-center color-error error-text mt-5">
                    {getSearchQueryDeatilsError}
                  </div>
                ) : (
                  <div className="my-md-4 my-4">
                    {showPopularAndRecentSearch ? (
                      <div>
                        {searchQueryListData?.recent_searches?.length > 0 ? (
                          <div className="search-type-section">
                            <div className="search-type">Recent Searches</div>
                            {getSearchList(
                              searchQueryListData?.recent_searches,
                              'recent',
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        {searchQueryListData?.popular_searches?.length > 0 ? (
                          <div className="search-type-section mt-5">
                            <div className="search-type">Popular</div>
                            {getSearchList(
                              searchQueryListData?.popular_searches,
                              'popular',
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchForm;
