import React from 'react';
import orderFormErrorStr from 'constants/errorStrings';


const ViewAsErrorTemplate = () => {
  return (
    <div className="d-flex col-12 flex-justify-center flex-items-center">
      <div className="unverified-outer-container d-flex flex-items-start">
        <div className="d-flex flex-column flex-items-center email-inner-container">
          <div>
            <div className="mt-3">
              {orderFormErrorStr.viewAsCommonError}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewAsErrorTemplate;
