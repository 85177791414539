import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { requestGetUserProfile } from 'redux/modules/userProfile/actions';
import UserUnverifiedTemplate from 'pages/UserUnverifiedTemplate';
import Dashboard from 'pages/Dashboard';
import OrderDashboard from 'pages/OrderDashboard';
import StyleGuide from 'pages/StyleGuide';
import SearchResult from 'pages/SearchResult';
import EmailVerification from 'pages/EmailVerification';
import PaymentSuccess from 'pages/PaymentSuccess';
import CheckIdleTimer from 'components/CheckIdleTimer';
import PaymentCancel from 'pages/PaymentCancel';
import Profile from 'pages/Profile';
import AuthLoader from 'components/AuthLoader';
import { useToken } from 'hooks/useToken';

const ProfileCheckRoute = () => {
  const dispatch = useDispatch();
  const { userData, userProfileError, isFetchProfileCompleted } = useSelector(
    ({ userProfile }) => userProfile,
  );
  const token = useToken();

  useEffect(() => {
    const fetchUserProfile = () => {
      if (token) {
        dispatch(
          requestGetUserProfile({
            token,
          }),
        );
      }
    };
    fetchUserProfile();
  }, [dispatch, token]);

  if (!isFetchProfileCompleted) {
    return <AuthLoader fullScreen={true} />;
  }

  if (userProfileError) {
    throw new Error(userProfileError);
  }

  if (userData && userData?.email && userData?.guid && isFetchProfileCompleted) {
    return (
      <>
        <CheckIdleTimer />
        <Switch>
          <Route path="/success">
            <PaymentSuccess />
          </Route>
          <Route path="/cancel">
            <PaymentCancel />
          </Route>
          <Route path="/email-verification-pending">
            <UserUnverifiedTemplate />
          </Route>
          <Route
            path={[
              '/dashboard/:tabID/:guid?',
              '/onboarding/personal-info',
              '/onboarding/terms-of-services',
              '/onboarding/plan-and-pricing',
            ]}
          >
            <Dashboard />
          </Route>
          <Route path="/order">
            <OrderDashboard />
          </Route>
          <Route exact path="/styleguide">
            <StyleGuide />
          </Route>
          <Route exact path="/orders/search/:tabID/:guid?">
            <SearchResult />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route path="/email-verification/:token/:email">
            <EmailVerification />
          </Route>
          <Route path="/">
            <Redirect to="/dashboard/personal" />
          </Route>
        </Switch>
      </>
    );
  }

  return null;
};

export default ProfileCheckRoute;
