import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Auth from 'pages/Auth';
import EmailVerification from 'pages/EmailVerification';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoader from 'components/AuthLoader';
import OrderDashboard from 'pages/OrderDashboard';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryModal from '../components/ErrorBoundaryModal';
import TopNav from 'components/TopNav';
import LogOutUtil from '../utils/LogOutUtil';
import ProfileCheckRoute from './ProfileCheckRoute';

const Routes = () => {
  const { logOutUser } = LogOutUtil();
  const { isAuthenticated, isLoading } = useAuth0();
  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return <ErrorBoundaryModal error={error} resetErrorBoundary={resetErrorBoundary} />;
  };

  const logOutCallback = () => {
    logOutUser();
  };
  return !isLoading ? (
    <Router>
      <div className="app-container d-flex col-12">
        <div className="position-relative flex-1">
          <TopNav />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              logOutCallback();
            }}
          >
            {isAuthenticated ? (
              <ProfileCheckRoute />
            ) : (
              <Switch>
                <Route path="/email-verification/:token/:email">
                  <EmailVerification />
                </Route>
                <Route path="/order">
                  <OrderDashboard />
                </Route>
                <Route exact path="/">
                  <Auth />
                </Route>
                <Route path="/">
                  <Auth />
                </Route>
              </Switch>
            )}
          </ErrorBoundary>
        </div>
      </div>
    </Router>
  ) : (
    <AuthLoader fullScreen={true} />
  );
};

export default Routes;
