import React from 'react';
import { ToggleablePanel } from 'components/Panel';
import { labels } from 'constants/labels';
import styled from 'styled-components';
import { ReactComponent as Plus } from 'assets/icons/Plus.svg';
import { ReactComponent as Minus } from 'assets/icons/Minus.svg';

const StyledCard = styled.div`
  padding: 20px 15px;
  background: var(--bg-gray-100);
  border-radius: var(--border-radius-8);
`;

const StyledLine = styled.div`
  border: ${(props) => props.border || '1px solid var(--border-gray-light)'};
`;

function SuggestedQuestions({ questions }) {
  if (questions && questions?.length) {
    return (
      <StyledCard className="d-flex flex-column gap-15">
        <div className="txt size-24 lh-40 w-500 text-black-900">
          {labels.suggestedFollowupQuestions}
        </div>

        <StyledLine />

        <div>
          {questions.map((question, index) => (
            <React.Fragment key={`question-${index}`}>
              <ToggleablePanel
                headercolor="var(--color-neutral-900)"
                header={question?.question}
                headerweight="500"
                expandIcon={<Plus />}
                collapseIcon={<Minus />}
              >
                <div className="txt size-15 lh-24 text-black-900 mt-3 pr-3">
                  {question?.answer}
                </div>
              </ToggleablePanel>

              {questions?.length - 1 !== index && (
                <StyledLine border="0.5px solid var(--border-gray-light)" />
              )}
            </React.Fragment>
          ))}
        </div>
      </StyledCard>
    );
  }

  return null;
}

export default SuggestedQuestions;
